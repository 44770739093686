import {combineReducers} from "redux";
import {accommodationScreenState} from "./accommodationScreenState";
import {assessmentConfig} from "./assessmentConfig";
import {bbdocState} from "./bbdocState";
import {error} from "./error";
import {flags} from "./flags";
import {getStartedScreenState} from "./getStartedScreenState";
import {helpDialogState} from "./helpDialogState";
import {interview} from "./interview";
import {interviewModuleScreenState} from "./interviewModuleScreenState";
import {interviewSurveyScreenState} from "./interviewSurveyScreenState";
import {m1InterviewScreenState} from "./m1InterviewScreenState";
import {m2InterviewScreenState} from "./m2InterviewScreenState";
import {serverInfo} from "./serverInfo";
import {testerScreenState} from "./testerScreenState";
import {toast} from "./toast";
import {zipUpload} from "./zipUpload";

const app = combineReducers({
  accommodationScreenState,
  assessmentConfig,
  bbdocState,
  error,
  flags,
  getStartedScreenState,
  helpDialogState,
  interview,
  interviewModuleScreenState,
  interviewSurveyScreenState,
  m1InterviewScreenState,
  m2InterviewScreenState,
  serverInfo,
  testerScreenState,
  toast,
  zipUpload,
});

export default app;
