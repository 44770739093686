import {
  createStyles,
  StyledComponentProps,
  withStyles,
} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import * as React from "react";
import {connect} from "react-redux";
import {setErrorMessage} from "../actions/errorActions";
import {LogAction, logFEInfo} from "../actions/serverInfoActions";
import {brand} from "../branding";
import {headerHeight} from "../components/Theme";
import Timer, {beginShowingSecondsAtDefault} from "../components/Timer";
import {
  getActiveModuleDurationMs,
  getActiveModuleId,
  getActiveModuleName,
  getActiveModuleOpenTimeMs,
  getModuleDurationMs,
  isActiveModuleInProgress,
} from "../helpers/interview";
import {AppState, Interview, ThunkDispatch} from "../reducers/types";
import {getServerTime as getServerTimeFn} from "../util/time";
import HelpDialog from "./HelpDialog";
import HelpDialogButton from "./HelpDialogButton";

const zipUploadTime = 5 * 60 * 1000; // 5 min.

interface Props extends StyledComponentProps {
  interview: Interview;
  error: string;
  isM2ScreenOpen: boolean;
  serverTimeOffset: number;
  getServerTime: () => number;
  setErrorMessage: (message: string) => void;
  logFEInfo: (action: LogAction, data: any) => void;
}

interface State {
  showAvatarMenu: boolean;
  showSignedOutDialog: boolean;
}

type TimerType = "module" | "m1" | "break" | "m2Zip" | "m2CloudShell" | "none";

export const gutterWidth = 15;
const styles = () =>
  createStyles({
    "@keyframes header": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    header: {
      alignItems: "center",
      animation: "$header 0.2s forwards",
      borderBottom: "solid 1px #ddd",
      display: "flex",
      flexShrink: 0,
      height: headerHeight,
      opacity: 0,
      paddingLeft: gutterWidth,
      paddingRight: gutterWidth,
    },
    logo: {
      height: 35,
      marginRight: 30,
    },
    right: {
      alignItems: "center",
      display: "flex",
      marginLeft: "auto",
    },
    helpButton: {
      marginLeft: 15,
    },
  });

class Header extends React.Component<Props, State> {
  getM2ImplementationEndTime(): number {
    const {interview} = this.props;
    if (!interview) return 0;
    const {m2OpenTime = 0} = interview;
    const m2Duration = getModuleDurationMs(interview, "m2");
    return m2OpenTime ? m2OpenTime + m2Duration : 0;
  }

  getModuleEndTime(): number {
    const {interview, getServerTime} = this.props;
    if (!interview) return 0;
    const openTimeMs = getActiveModuleOpenTimeMs(interview, getServerTime());
    const durationMs = getActiveModuleDurationMs(interview, getServerTime());
    return openTimeMs ? openTimeMs + (durationMs ?? 0) : 0;
  }

  getTimerType(): TimerType {
    const {interview, isM2ScreenOpen, getServerTime} = this.props;
    if (!interview) return "none";
    const {language, m1EndTime, m2OpenTime, m2EndTime, m2Type} = interview;
    const breakDuration = getModuleDurationMs(interview, "break");
    if (!language) return "none";
    if (!m1EndTime) {
      return "m1";
    } else if (
      !isM2ScreenOpen &&
      !m2OpenTime &&
      m1EndTime + breakDuration > getServerTime()
    ) {
      return "break";
    } else if (!m2EndTime) {
      if (m2Type === "zip") {
        return "m2Zip";
      } else if (m2Type === "cloudshell") {
        return "m2CloudShell";
      }
    }
    return "none";
  }

  @autobind
  onTimeExpired(): void {
    this.forceUpdate();
  }

  renderTimer(): React.ReactNode {
    if (this.props.error) return null;
    if (!this.props.interview?.modules) return null;
    switch (this.getTimerType()) {
      case "module":
        return this.renderModuleTimer();
      case "m1":
        return this.renderM1Timer();
      case "break":
        return this.renderBreakTimer();
      case "m2Zip":
        return this.renderM2ZipTimer();
      case "m2CloudShell":
        return this.renderM2CloudShellTimer();
      case "none":
      default:
        return null;
    }
  }

  renderModuleTimer(): React.ReactNode {
    const {interview, getServerTime} = this.props;
    const started = isActiveModuleInProgress(interview, getServerTime());
    const activeModuleId = getActiveModuleId(interview, getServerTime());
    const activeModuleName = getActiveModuleName(interview, getServerTime());
    let label = started ? `${activeModuleName} Time Remaining` : "";
    if (activeModuleId === "break") {
      label = "Break Time Remaining";
    }
    const key = getActiveModuleId(interview, getServerTime());
    const beginShowingSecondsAt =
      activeModuleId === "break"
        ? interview.breakDuration
        : beginShowingSecondsAtDefault;
    return (
      <Timer
        started={started}
        beginShowingSecondsAt={beginShowingSecondsAt}
        key={key}
        endTime={this.getModuleEndTime()}
        label={label}
        onTimeExpired={this.onTimeExpired}
        getServerTime={this.props.getServerTime}
      />
    );
  }

  renderM1Timer(): React.ReactNode {
    const {m1OpenTime = 0} = this.props.interview;
    const m1Duration = getModuleDurationMs(this.props.interview, "m1");
    const started = Boolean(m1OpenTime);
    const endTime = m1OpenTime ? m1OpenTime + m1Duration : 0;

    // TODO: Remove this logging code once the timer issue is fixed.
    // Sometimes candidates get more time than they should for m1 and m2. When
    // this happens, display an error page and send info to the server to be
    // logged.
    // Add a buffer so we don't get a false positive.
    const timerValueBuffer = 10000;
    // Catch potential case where m1 duration is way too high (> 100 min).
    const maxM1Duration = 1000 * 60 * 100;
    // m1OpenTime should always be less than the current server time.
    if (
      m1OpenTime > this.props.getServerTime() + timerValueBuffer ||
      m1Duration > maxM1Duration
    ) {
      this.props.setErrorMessage("Failed to initialize the timer.");
      let timeZoneOffset = "";
      try {
        timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (err) {
        // No-op.
      }
      this.props.logFEInfo(LogAction.TIMER_OUT_OF_SYNC, {
        m1OpenTime,
        m1Duration,
        endTime,
        timeZoneOffset,
        clientTime: Date.now(),
        serverTime: this.props.getServerTime(),
        serverTimeOffset: this.props.serverTimeOffset,
      });
    }
    return (
      <Timer
        started={started}
        key="part1"
        endTime={endTime}
        label="Part 1 Time Remaining"
        getServerTime={this.props.getServerTime}
      />
    );
  }

  renderBreakTimer(): React.ReactNode {
    const {m1EndTime = 0} = this.props.interview;
    const breakDuration = getModuleDurationMs(this.props.interview, "break");
    const endTime = m1EndTime + breakDuration;
    return (
      <Timer
        started={true}
        endTime={endTime}
        key="break"
        label="Break Time Remaining"
        beginShowingSecondsAt={breakDuration}
        getServerTime={this.props.getServerTime}
      />
    );
  }

  renderM2ZipTimer(): React.ReactNode {
    const {interview, getServerTime} = this.props;
    const started = Boolean(interview.m2OpenTime);
    let endTime = this.getM2ImplementationEndTime();
    let label = "";
    let key = "part2";
    let beginShowingSecondsAt = beginShowingSecondsAtDefault;
    if (!started) {
      // No need to have a label.
    }
    if (endTime > getServerTime()) {
      label = "Part 2 Implementation Time Remaining";
    } else if (endTime + zipUploadTime > getServerTime()) {
      label = "Part 2 Upload Time Remaining";
      key = "part2Upload";
      endTime = endTime + zipUploadTime;
      beginShowingSecondsAt = zipUploadTime;
    }
    return (
      <Timer
        started={started}
        key={key}
        endTime={endTime}
        label={label}
        beginShowingSecondsAt={beginShowingSecondsAt}
        onTimeExpired={this.onTimeExpired}
        getServerTime={this.props.getServerTime}
      />
    );
  }

  renderM2CloudShellTimer(): React.ReactNode {
    return (
      <Timer
        started={Boolean(this.props.interview.m2OpenTime)}
        key="part2"
        endTime={this.getM2ImplementationEndTime()}
        label="Part 2 Time Remaining"
        getServerTime={this.props.getServerTime}
      />
    );
  }

  render(): React.ReactNode {
    const {classes, interview} = this.props;
    if (!classes) return;
    return (
      <header className={classes.header}>
        <img
          className={classes.logo}
          src={brand.logo}
          alt={`${brand.company} Logo`}
        />
        <nav className={classes.right}>
          {this.renderTimer()}
          {interview?.modules && (
            <>
              <HelpDialogButton className={classes.helpButton} />
              <HelpDialog />
            </>
          )}
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  error: state.error.errorMessage,
  interview: state.interview,
  isM2ScreenOpen: state.m2InterviewScreenState.isScreenOpen,
  serverTimeOffset: state.serverInfo.serverTimeOffset,
  getServerTime: () => getServerTimeFn(state.serverInfo.serverTimeOffset),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): Partial<Props> => ({
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
  logFEInfo: (action: LogAction, data: any) => {
    return dispatch(logFEInfo(action, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Header));
