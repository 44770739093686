import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {testerZipUrl} from "../helpers/urls/interviewUrls";
import {AppState, Language, M2Type} from "../reducers/types";
import {ErrorAction, TesterScreenAction} from "./actionTypes";
import {setErrorMessage} from "./errorActions";
import {createInterview} from "./interviewActions";

type Action = TesterScreenAction | ErrorAction;
type AsyncAction<T> = ThunkAction<Promise<T>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

const downloadDelay = 2000;

export function isDownloadingTesterZip(value: boolean): TesterScreenAction {
  return {
    type: "TESTER_SCREEN.IS_DOWNLOADING_ZIP",
    value,
  };
}

export function toggleInstructions(): TesterScreenAction {
  return {
    type: "TESTER_SCREEN.TOGGLE_INSTRUCTIONS",
  };
}

/**
 * @returns Whether or not the interview was created (or already existed).
 */
export function submit(
  atsId: string,
  language?: Language,
  m2Type?: M2Type,
): AsyncAction<boolean> {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch({
      type: "TESTER_SCREEN.IS_SUBMITTING",
      value: true,
    });
    try {
      await createInterview(atsId, language, m2Type);
      return true;
    } catch (err) {
      dispatch(
        setErrorMessage(
          "Your interview has failed to load. Look out for an email from our support team with next steps shortly.",
          {
            hideTips: true,
          },
        ),
      );
      return false;
    }
  };
}

export function downloadZip(language: Language | "csv"): AsyncAction<void> {
  return async (
    dispatch: Dispatch,
    getState: () => AppState,
  ): Promise<void> => {
    try {
      const {role} = getState().interview;
      dispatch(isDownloadingTesterZip(true));
      // @ts-ignore FIXME: strictNullChecks
      window.location.assign(testerZipUrl(language, role));
      // Emulate async download
      await new Promise((resolve) => setTimeout(resolve, downloadDelay));
      dispatch(isDownloadingTesterZip(false));
    } catch (err) {
      console.error("Error downloading tester files:", err);
      dispatch(setErrorMessage("Unable to download tester files."));
    }
  };
}
