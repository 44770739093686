import {
  createStyles,
  StyledComponentProps,
  withStyles,
} from "@material-ui/core/styles";
import {getAuth, onAuthStateChanged, Unsubscribe} from "firebase/auth";
import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {activeScreenNotFound, getActiveScreen} from "../Routes";
import {fetchFlags} from "../actions/flagsActions";
import {fetchServerTimeOffset} from "../actions/serverInfoActions";
import Spinner from "../components/Spinner";
import Toast from "../components/Toast";
import {getUser} from "../helpers/auth";
import getAtsIdFromUrl from "../helpers/urls/getAtsIdFromUrl";
import {
  AppError,
  AppState,
  Flags,
  ServerInfo,
  ThunkDispatch,
} from "../reducers/types";
import ErrorScreen from "../screens/ErrorScreen";
import PageNotFoundScreen from "../screens/PageNotFoundScreen";
import SignInScreen from "../screens/SignInScreen";
import Header from "./Header";

interface Props extends StyledComponentProps, RouteComponentProps {
  error: AppError;
  fetchFlags: () => any;
  fetchServerTimeOffset: () => any;
  flags: Flags;
  serverInfo: ServerInfo;
}

const styles = () =>
  createStyles({
    shell: {
      color: "#505050",
      display: "flex",
      flexDirection: "column",
      fontFamily: "roboto, arial",
      fontSize: "14px",
      height: "100%",
      lineHeight: "1.5em",
      overflow: "hidden",
    },
    content: {
      flex: 1,
      position: "relative",
    },
  });

class Shell extends React.Component<Props> {
  unsubscribe: Unsubscribe | null = null;

  async componentDidMount() {
    const auth = getAuth();
    this.unsubscribe = onAuthStateChanged(auth, () => this.forceUpdate());

    await Promise.all([
      this.props.fetchFlags(),
      this.props.fetchServerTimeOffset(),
    ]);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  isInitialized(): boolean {
    const {flags, serverInfo} = this.props;
    return Boolean(Object.keys(flags).length && serverInfo.serverTimeOffset);
  }

  shouldBypassAuth(): boolean {
    const activeScreen = getActiveScreen();
    return (
      !activeScreen ||
      activeScreen.name === "accommodation" ||
      activeScreen.name === "applicant" ||
      window.location.pathname.includes("/codecollab/")
    );
  }

  shouldRenderHeader(): React.ReactNode {
    return this.isInitialized() && !getActiveScreen()?.hideNavBar;
  }

  renderContent(): React.ReactNode {
    const {children, error} = this.props;
    if (activeScreenNotFound()) {
      return <PageNotFoundScreen />;
    } else if (error.errorMessage) {
      return <ErrorScreen />;
    } else if (!this.shouldBypassAuth() && getUser() === null) {
      return <SignInScreen atsId={getAtsIdFromUrl()} />;
    } else if (!this.isInitialized()) {
      return <Spinner variant="logo" />;
    }
    return children;
  }

  render(): React.ReactNode {
    const {classes} = this.props;
    if (this.shouldRenderHeader()) {
      return (
        <div className={classes?.shell}>
          <Header />
          <main className={classes?.content}>{this.renderContent()}</main>
          <Toast />
        </div>
      );
    } else {
      return (
        <div className={classes?.shell}>
          {this.renderContent()}
          <Toast />
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  error: state.error,
  flags: state.flags,
  serverInfo: state.serverInfo,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchFlags: () => dispatch(fetchFlags()),
  fetchServerTimeOffset: () => dispatch(fetchServerTimeOffset()),
});

// Need `withRouter` to fix a weird react-router bug.
// See https://github.com/ReactTraining/react-router/issues/4671
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Shell)),
);
