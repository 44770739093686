import {AnyAction} from "redux";
import {ThunkAction} from "redux-thunk";
import io from "socket.io-client";
import {getAPIBaseUrl} from "../helpers/api";
import {getUserIdToken} from "../helpers/auth";
import {
  createInterviewUrl,
  fetchInterviewUrl,
  m1EndTimeUrl,
  endInterviewUrl,
  saveSurveyUrl,
  saveTermsUrl,
} from "../helpers/urls/interviewUrls";
import {
  Candidate,
  Interview,
  Language,
  M2Type,
  ThunkDispatch,
} from "../reducers/types";
import {Gender, Race} from "../types/types";
import {get, post} from "../util/fetch";
import {InterviewAction} from "./actionTypes";
import {setErrorMessage, UnexpectedServerResponse} from "./errorActions";
import {errorTips} from "./fetchActions";
import {isSubmittingM1} from "./m1InterviewScreenActions";
import {isSubmittingM2} from "./m2InterviewScreenActions";

export const IS_SUBMITTING_M2 = "IS_SUBMITTING_M2";
export const SET_TERMS = "SET_TERMS";
export const SET_INTERVIEW = "SET_INTERVIEW";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_M1_OPEN_TIME = "SET_M1_OPEN_TIME";
export const SET_M1_END_TIME = "SET_M1_END_TIME";
export const SET_M2_OPEN_TIME = "SET_M2_OPEN_TIME";
export const SET_M2_END_TIME = "SET_M2_END_TIME";
export const SET_M2_TYPE = "SET_M2_TYPE";
export const SET_SURVEY_STARS = "SET_SURVEY_STARS";
export const UPDATE_CANDIDATE = "UPDATE_CANDIDATE";

export function setInterview(interview: Interview): InterviewAction {
  return {
    type: SET_INTERVIEW,
    interview,
  };
}

export function setM1OpenTime(value: number): InterviewAction {
  return {
    type: SET_M1_OPEN_TIME,
    value,
  };
}

function updateCandidate(candidate: Candidate): InterviewAction {
  return {
    type: UPDATE_CANDIDATE,
    candidate,
  };
}

/**
 * Creates a new interview object for the current user with the provided
 * atsId.
 */
export async function createInterview(
  atsId: string,
  language?: Language,
  m2Type?: M2Type,
): Promise<void> {
  try {
    const data = {
      atsId,
      language,
      m2Type,
    };
    await post(createInterviewUrl(), data, {
      token: await getUserIdToken(),
    });
  } catch (err) {
    console.error("Error creating interview:", err);
    throw err;
  }
}

export function fetchInterview(
  atsId: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      const res = await get(fetchInterviewUrl(atsId), {
        token: await getUserIdToken(),
      });
      const json = await res.json();
      const {error} = json;
      if (error) {
        console.error("Error finding interview:", error);
        dispatch(
          setErrorMessage(
            "Your interview has failed to load. Look out for an email from our support team with next steps shortly.",
            {
              hideTips: true,
            },
          ),
        );
      } else {
        dispatch(setInterview(json));
      }
    } catch (err) {
      console.error("Error loading interview:", err);
      dispatch(
        setErrorMessage("Failed to connect to the back end.", {
          tips: errorTips,
        }),
      );
    }
  };
}

export function streamCandidate(
  atsId: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      const socketHost = getAPIBaseUrl();
      const socket = io(socketHost, {
        transports: ["polling"],
        query: `atsId=${atsId}`,
      });

      socket.on("candidate", (data: any) => {
        const candidate = data.candidate as Candidate;
        dispatch(updateCandidate(candidate));
        if (candidate.expired) {
          dispatch(
            setErrorMessage(
              "Your interview link is no longer valid." +
                " Please reach out to your recruiter to request an extension.",
              {hideTips: true, isNotification: true},
            ),
          );
        } else if (candidate.incomplete) {
          dispatch(
            setErrorMessage("Your interview time has elapsed.", {
              hideTips: true,
              isNotification: true,
            }),
          );
        } else if (candidate.repeatCandidateNoAssessments || candidate.error) {
          dispatch(
            setErrorMessage(
              "Your interview has failed to load. Look out for an email from our support team with next steps shortly.",
              {
                hideTips: true,
              },
            ),
          );
        }
      });
    } catch (err) {
      console.error("Websocket error:", err);
      dispatch(
        setErrorMessage("Failed to connect to the back end.", {
          tips: errorTips,
        }),
      );
    }
  };
}

export function saveTerms(
  interview: Interview,
): ThunkAction<Promise<void>, {}, {}, InterviewAction> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      const data = {atsId: interview.id};
      const response = await post(saveTermsUrl(), data, {
        token: await getUserIdToken(),
      });
      const json = await response.json();
      if (json.done) {
        dispatch({
          type: SET_TERMS,
        });
      } else {
        throw new UnexpectedServerResponse(json);
      }
    } catch (err) {
      dispatch(setErrorMessage("Unable to save terms agreement."));
      throw err;
    }
  };
}

export function submitM1(
  interview: Interview,
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    dispatch(isSubmittingM1(true));
    const data = {atsId: interview.id};
    const response = await post(m1EndTimeUrl(), data, {
      token: await getUserIdToken(),
    });
    const json = await response.json();
    const {m1EndTime} = json;
    if (Number.isInteger(m1EndTime)) {
      dispatch({
        type: SET_M1_END_TIME,
        value: m1EndTime,
      });
    }
    // TODO: Add error handling
    // Wait before changing the state so the button doesn't flash.
    await new Promise((resolve) => setTimeout(resolve, 100));
    dispatch(isSubmittingM1(false));
  };
}

export function submitM2(
  interview: Interview,
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    dispatch(isSubmittingM2(true));
    const data = {atsId: interview.id};
    const response = await post(endInterviewUrl(), data, {
      token: await getUserIdToken(),
    });
    const json = await response.json();
    const {m2EndTime} = json;
    if (Number.isInteger(m2EndTime)) {
      dispatch({
        type: SET_M2_END_TIME,
        value: m2EndTime,
      });
    }
    // TODO: Add error handling
    // Wait before changing the state so the button doesn't flash.
    await new Promise((resolve) => setTimeout(resolve, 100));
    dispatch(isSubmittingM2(false));
  };
}

export function saveSurvey(
  interview: Interview,
  stars: number,
  feedback: string,
  gender: Gender,
  races: Race[],
) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      const data = {
        atsId: interview.id,
        ...(stars ? {stars} : {}),
        feedback,
        gender,
        races,
      };
      const response = await post(saveSurveyUrl(interview.id), data, {
        token: await getUserIdToken(),
      });
      const json = await response.json();
      if (json.done) {
        dispatch({type: SET_SURVEY_STARS, value: stars});
      } else {
        throw new UnexpectedServerResponse(json);
      }
    } catch (err) {
      dispatch(setErrorMessage("Unable to save survey."));
      throw err;
    }
  };
}

export function getSupportEmail(): string {
  return "support@byteboard.dev";
}
