import {getAPIBaseUrl} from "../api";

const interviewBaseUrl = getAPIBaseUrl() + "/interview";

export function serverTimeUrl() {
  return `${getAPIBaseUrl()}/serverinfo/servertime`;
}

export function bbDocCandidateResponseUrl(
  atsId: string,
  responseId: string,
): string {
  return `${interviewBaseUrl}/${atsId}/response/${responseId}`;
}

export function bbDocUrl(atsId: string, moduleId: string) {
  return `${interviewBaseUrl}/${atsId}/interviewdoc/${moduleId}`;
}

export function hasModifiedM2CodeUrl(atsId: string) {
  return `${interviewBaseUrl}/hasmodifiedm2code/${atsId}`;
}

export function unresolveCommentsUrl() {
  return `${interviewBaseUrl}/unresolveComments`;
}

export function logFEInfoUrl() {
  return `${interviewBaseUrl}/log`;
}

export function projectNameUrl(atsId: string) {
  return `${interviewBaseUrl}/projectName/${atsId}`;
}

export function helpEmailUrl() {
  return `${interviewBaseUrl}/help`;
}

export function testerZipUrl(language: string, role: string) {
  return `${interviewBaseUrl}/tester/${language}/${role}`;
}

export function m2BaseZipDownloadUrl(atsId: string) {
  return `${interviewBaseUrl}/m2BaseZip/${atsId}`;
}

export function m2CandidateZipDownloadUrl(atsId: string) {
  return `${interviewBaseUrl}/m2candidatezip/${atsId}`;
}

export function flagsUrl() {
  return `${interviewBaseUrl}/flags`;
}

export function runShFileNameUrl(atsId: string) {
  return `${interviewBaseUrl}/runSh/${atsId}`;
}

export function m2AdditionalTimeDescriptionUrl(atsId: string) {
  return `${interviewBaseUrl}/m2additionaltimedescription/${atsId}`;
}
export function saveSurveyUrl(atsId: string) {
  return `${interviewBaseUrl}/saveSurvey/${atsId}`;
}

export function m1OpenTimeUrl() {
  return `${interviewBaseUrl}/m1OpenTime`;
}

export function m1EndTimeUrl() {
  return `${interviewBaseUrl}/m1EndTime`;
}

export function m2OpenTimeUrl() {
  return `${interviewBaseUrl}/m2OpenTime`;
}

export function endInterviewUrl() {
  return `${interviewBaseUrl}/endInterview`;
}

export function m2UploadUrl(atsId: string) {
  return `${interviewBaseUrl}/m2ZipFile/${atsId}`;
}

export function fetchInterviewUrl(atsId: string) {
  return `${interviewBaseUrl}/${atsId}`;
}

export function liveCodingInterviewUrl(liveCodingInterviewId: string) {
  return `${interviewBaseUrl}/liveCodingInterviews/${liveCodingInterviewId}`;
}

export function liveCodingConfigurationUrl(liveCodingInterviewId: string) {
  return `${interviewBaseUrl}/liveCodingConfiguration/${liveCodingInterviewId}`;
}

export function saveTermsUrl() {
  return `${interviewBaseUrl}/terms`;
}

export function updateInterviewUrl(atsId: string) {
  return `${interviewBaseUrl}/update/${atsId}`;
}

export function createInterviewUrl() {
  return `${interviewBaseUrl}`;
}
