import {compact} from "lodash";
import moment from "moment";
import {
  languageInstallCommandMap,
  languageNameMapWithVersions,
} from "../config.js";
import {
  Flags,
  Interview,
  InterviewModule,
  Language,
  LanguageMap,
  M2InterviewScreenState,
  ModuleId,
  ModuleType,
} from "../reducers/types";
import {BrowserName, getBrowserName} from "../util/browser";
import {msToMin} from "../util/time";

// Updated on Oct 7th, 2024
// https://cloud.google.com/shell/docs/quotas-limits#browser_support
const cloudShellSupportedBrowsers: BrowserName[] = [
  "chrome",
  "edge",
  "firefox",
  "ie",
  // Having third-party cookies disabled or using private browsing mode will break Cloud Shell in Safari
  "safari",
];

export function isWebInterview(interview: Interview): boolean {
  return interview.role === "web";
}

export function isGenSweInterview(interview: Interview): boolean {
  // The role field isn't set for every candidate yet, so assume it's 'genSwe'
  // if unset.
  return interview.role === "genSwe" || !interview.role;
}

export function isMobileInterview(interview: Interview): boolean {
  return interview.role === "mobile";
}

export function isDataEngInterview(interview: Interview): boolean {
  return interview.role === "dataEng";
}

export function isSREInterview(interview: Interview): boolean {
  return interview.role === "sre";
}

export function isSecurityInterview(interview: Interview): boolean {
  return interview.role === "securityEng";
}

export function isDataAnalysisInterview(interview: Interview): boolean {
  return interview.role === "dataAnalysis";
}

export function isStaffEngInterview(interview: Interview): boolean {
  return interview.role === "staffEng";
}

export function isAppliedAIInterview(interview: Interview): boolean {
  return interview.role === "appliedAI";
}

export function getDisplayInterviewType(interview: Interview): string {
  switch (interview.role) {
    case "genSwe":
      return "Software Engineering";
    case "sre":
      return "Software Engineering - Systems";
    case "web":
      return "Web Development";
    case "mobile":
      return "Mobile Engineering";
    case "dataEng":
      return "Data Engineering";
    case "dataAnalysis":
      return "Data Analysis";
    case "securityEng":
      return "Security Engineering";
    case "staffEng":
      return "Staff Software Engineering";
    case "appliedAI":
      return "Applied AI Engineering";
    default:
      return "";
  }
}

export function isZipM2(interview: Interview): boolean {
  return interview.m2Type === "zip";
}

export function isOnlineEditorM2(interview: Interview): boolean {
  return interview.m2Type === "cloudshell";
}

export function isCloudShellM2(interview: Interview, flags: Flags): boolean {
  return hasM2CloudShellOption(interview, flags) && isOnlineEditorM2(interview);
}

export function isInterviewGenerated(interview: Interview): boolean {
  // The signup time is set when the interview generation starts. We want to
  // treat the interview as "generated" even if docs/buckets are still being
  // created so that users don't have to wait 20+ seconds before moving on.
  return Boolean(interview.signupTime);
}

export function hasM2ZipOption(interview: Interview): boolean {
  return (
    isGenSweInterview(interview) ||
    isMobileInterview(interview) ||
    isDataEngInterview(interview) ||
    isSREInterview(interview) ||
    isWebInterview(interview) ||
    isSecurityInterview(interview) ||
    isDataAnalysisInterview(interview) ||
    isStaffEngInterview(interview) ||
    isAppliedAIInterview(interview)
  );
}

export function hasM2CloudShellOption(
  interview: Interview,
  flags: Flags,
  opts: {ignoreUserBrowserType?: boolean} = {},
): boolean {
  // Provide the option to ignore the user's browser type
  // so that we can determine if Cloud Shell would be an option
  // in a different browser.
  if (!opts.ignoreUserBrowserType && !activeBrowserSupportsCloudShell()) {
    return false;
  }
  return (
    (flags.cloudshellEnabled &&
      (isGenSweInterview(interview) ||
        isDataEngInterview(interview) ||
        isSREInterview(interview) ||
        isSecurityInterview(interview) ||
        isStaffEngInterview(interview) ||
        isAppliedAIInterview(interview))) ||
    isWebInterview(interview)
  );
}

export function activeBrowserSupportsCloudShell(): boolean {
  return cloudShellSupportedBrowsers.includes(getBrowserName());
}

export function canSwitchM2Type(interview: Interview, flags: Flags): boolean {
  return hasM2ZipOption(interview) && hasM2CloudShellOption(interview, flags);
}

export function hasAssessmentId(interview: Interview): boolean {
  return Boolean(interview.assessmentId);
}

export function getModuleDurationMs(
  interview: Interview,
  moduleId: ModuleId,
): number {
  switch (moduleId) {
    case "m1":
      return interview.m1Duration ?? 0;
    case "m2":
      return interview.m2Duration ?? 0;
    case "break":
      return interview.breakDuration ?? 0;
    default:
      return 0;
  }
}

export function isActiveDocModuleWithComments(
  interview: Interview,
  serverTime: number,
): boolean {
  return isDocModuleActive(interview, serverTime);
}

export function isDocModuleActive(
  interview: Interview,
  serverTime: number,
): boolean {
  return interview && getActiveModuleType(interview, serverTime) === "document";
}

export function isCodeModuleActive(
  interview: Interview,
  serverTime: number,
): boolean {
  return interview && getActiveModuleType(interview, serverTime) === "code";
}

export function isCodeModuleActiveOrNextAfterBreak(
  interview: Interview,
  serverTime: number,
): boolean {
  return (
    isCodeModuleActive(interview, serverTime) ||
    (isBreakModuleActive(interview, serverTime) &&
      getNextModuleType(interview, serverTime) === "code")
  );
}

function isBreakModuleActive(
  interview: Interview,
  serverTime: number,
): boolean {
  return interview && getActiveModuleType(interview, serverTime) === "break";
}

export function isInterviewStarted(interview: Interview): boolean {
  return interview && Boolean(interview.m1OpenTime);
}

export function getActiveModuleName(
  interview: Interview,
  serverTime: number,
): string {
  const activeModuleId = getActiveModuleId(interview, serverTime);
  return getModuleName(activeModuleId);
}

export function getModuleType(
  interview: Interview,
  moduleId: ModuleId,
): ModuleType | null {
  if (!isInterviewGenerated(interview)) {
    return null;
  }
  // @ts-ignore FIXME: strictNullChecks
  return interview.modules.find((module) => module.id === moduleId)?.type;
}

export function isDocModuleType(moduleType: ModuleType): boolean {
  return moduleType === "document";
}

export function isCodeModuleType(moduleType: ModuleType): boolean {
  return moduleType === "code";
}

export function getActiveModuleType(
  interview: Interview,
  serverTime: number,
): ModuleType | null {
  const activeModuleId = getActiveModuleId(interview, serverTime);
  return getModuleType(interview, activeModuleId);
}

export function getNextModuleType(
  interview: Interview,
  serverTime: number,
): ModuleType | null {
  const nextModule = getNextModule(interview, serverTime);
  return getModuleType(interview, nextModule.id);
}

export function getModuleName(moduleId: ModuleId): string {
  switch (moduleId) {
    case "m1":
      return "Part 1";
    case "m2":
      return "Part 2";
    case "break":
      return "Break";
    default:
      return "";
  }
}

/**
 * Returns the interview module id that is currently active. Returns the first
 * module's id if the interview hasn't started yet and the last module's id if
 * the interview is complete.
 */
export function getActiveModuleId(
  interview: Interview,
  serverTime: number,
): ModuleId {
  if (!interview.modules) {
    throw new Error(
      `Cannot get active module id for interview without modules`,
    );
  }
  for (const interviewModule of interview.modules) {
    if (!isModuleComplete(interview, interviewModule.id, serverTime)) {
      return interviewModule.id;
    }
  }
  return interview.modules[interview.modules.length - 1].id;
}

function getNextModule(
  interview: Interview,
  serverTime: number,
): InterviewModule {
  if (!interview.modules) {
    throw new Error(`Cannot get next module for interview without modules`);
  }
  const activeModuleId = getActiveModuleId(interview, serverTime);
  const activeModuleIndex = interview.modules.findIndex((module) => {
    return module.id === activeModuleId;
  });
  const nextModuleIndex = Math.min(
    activeModuleIndex + 1,
    interview.modules.length - 1,
  );
  return interview.modules[nextModuleIndex];
}

export function isActiveModuleInProgress(
  interview: Interview,
  serverTime: number,
): boolean {
  const activeModuleId = getActiveModuleId(interview, serverTime);
  return isModuleInProgress(activeModuleId, interview, serverTime);
}

export function isModuleInProgress(
  moduleId: ModuleId,
  interview: Interview,
  serverTime: number,
): boolean {
  if (isModuleComplete(interview, moduleId, serverTime)) return false;
  return Boolean(getOpenTimeMs(interview, moduleId));
}

export function isModuleComplete(
  interview: Interview,
  moduleId: ModuleId,
  serverTime: number,
): boolean {
  switch (moduleId) {
    case "m1":
      return Boolean(interview.m1EndTime);
    case "m2":
      return Boolean(interview.m2EndTime);
    case "break":
      // The break is considered complete if the next module is started or if
      // the break duration has passed.
      const breakIndex = interview.modules?.findIndex(
        (module) => module.id === "break",
      );
      if (breakIndex && interview.modules) {
        const moduleAfterBreakId = interview.modules[breakIndex + 1].id;
        return (
          Boolean(getOpenTimeMs(interview, moduleAfterBreakId)) ||
          // @ts-ignore FIXME: strictNullChecks
          getEndTimeMs(interview, "break") < serverTime
        );
      }
    default:
      return true;
  }
}

export function isInterviewFinished(
  interview: Interview,
  serverTime: number,
): boolean {
  if (!interview.modules) {
    throw new Error(
      `Cannot determine if interview is finished without modules`,
    );
  }
  const lastModule = interview.modules[interview.modules.length - 1];
  // @ts-ignore FIXME: strictNullChecks
  return (
    isModuleComplete(interview, lastModule.id, serverTime) ||
    interview.expired ||
    interview.incomplete
  );
}

export function isActiveModule(
  interview: Interview,
  moduleId: ModuleId,
  serverTime: number,
): boolean {
  return getActiveModuleId(interview, serverTime) === moduleId;
}

export function getIframeUrl(
  docId: string | undefined,
  docUrl: string | null | undefined,
  urlParams: {[x: string]: string},
): string {
  let baseUrl;
  if (docUrl) {
    baseUrl = docUrl;
  } else if (docId) {
    baseUrl = `https://docs.google.com/document/d/${docId}/edit`;
  }

  if (!baseUrl) return "";
  const rootUrl = new URL(baseUrl);
  Object.keys(urlParams).forEach((key: string) => {
    rootUrl.searchParams.set(key, urlParams[key]);
  });
  return rootUrl.href;
}

export function getOpenTimeFieldNameForModule(
  moduleId: ModuleId,
): keyof Interview | undefined {
  switch (moduleId) {
    case "m1":
      return "m1OpenTime";
    case "m2":
      return "m2OpenTime";
  }
}

export function getOpenTimeMs(
  interview: Interview,
  moduleId: ModuleId,
): number | undefined {
  if (moduleId === "break") {
    return getBreakOpenTimeMs(interview);
  }
  const fieldName = getOpenTimeFieldNameForModule(moduleId);
  return fieldName ? (interview[fieldName] as number) : undefined;
}

function getBreakOpenTimeMs(interview: Interview): number {
  if (!interview.modules) {
    throw new Error(`Cannot get break open time for interview without modules`);
  }
  // The break open time is the end time of the previous module.
  const breakIndex = interview.modules.findIndex(
    (module) => module.id === "break",
  );
  const previousModuleId = interview.modules[breakIndex - 1].id;
  // @ts-ignore FIXME: strictNullChecks
  return getEndTimeMs(interview, previousModuleId);
}

export function getActiveModuleOpenTimeMs(
  interview: Interview,
  serverTime: number,
): number | undefined {
  const activeModuleId = getActiveModuleId(interview, serverTime);
  if (activeModuleId) {
    return getOpenTimeMs(interview, activeModuleId);
  }
}

export function getEndTimeFieldNameForModule(
  moduleId: ModuleId,
): keyof Interview | undefined {
  switch (moduleId) {
    case "m1":
      return "m1EndTime";
    case "m2":
      return "m2EndTime";
  }
}

export function getEndTimeMs(
  interview: Interview,
  moduleId: ModuleId,
): number | undefined {
  if (moduleId === "break") {
    return (
      getBreakOpenTimeMs(interview) + getModuleDurationMs(interview, "break")
    );
  }
  const fieldName = getEndTimeFieldNameForModule(moduleId);
  return fieldName ? (interview[fieldName] as number) : undefined;
}

export function getActiveModuleDurationMs(
  interview: Interview,
  serverTime: number,
): number | undefined {
  const activeModuleId = getActiveModuleId(interview, serverTime);
  if (activeModuleId) {
    return getModuleDurationMs(interview, activeModuleId);
  }
}

export function getModuleIdFromUrl(): ModuleId | undefined {
  const {pathname} = window.location;
  const [p, moduleId]: any =
    pathname.match(/interview\/part\/(.*)\/(?:.*)/) || [];
  return moduleId;
}

export function getTesterZipCommand(language: Language): string {
  if (language === "csharp") {
    return "dotnet run";
  } else if (language === "ts") {
    return "npm install && sh run_hello.sh";
  } else {
    return "sh run_hello.sh";
  }
}

export function requiresNpmInstall(interview: Interview): boolean {
  return (
    isWebInterview(interview) ||
    (interview.language === "js" && isSecurityInterview(interview)) ||
    interview.language === "ts"
  );
}

export const npmInstallCommand: string = "npm install";
export const npmStartCommand: string = "npm start";

export const generateLanguagesForInterviewCenter = (
  interview: Interview,
): string => {
  const availableLanguages: Language[] = interview.languageOptions || [];

  if (isWebInterview(interview)) {
    languageNameMapWithVersions.js = languageNameMapWithVersions.js_web;
    languageNameMapWithVersions.ts = languageNameMapWithVersions.ts_web;
  }

  const availableLanguagesMap: LanguageMap[] = availableLanguages.map(
    (language: Language) => ({
      value: language,
      label: (languageNameMapWithVersions as any)[language],
    }),
  );

  const availableLanguagesString: string[] = availableLanguagesMap
    .map((languageMap: LanguageMap) => {
      return languageMap.label;
    })
    .sort();

  return availableLanguagesString.join(", ");
};

export function getCloudShellInterviewSetupCode({
  m2BucketId,
  language,
}: Interview): string {
  return compact([
    "mkdir ~/coding-challenge",
    `&& gsutil cp -r gs://${m2BucketId}/* ~/coding-challenge`,
    // @ts-expect-error FIXME: We should properly type this imported object
    languageInstallCommandMap[language]?.trim(),
    "&& sh ~/coding-challenge/admin/start_interview.sh",
    "&& cloudshell ws ~/coding-challenge/code",
  ]).join(" ");
}

export const getCloudShellEarlySubmitCode = (): string =>
  "sh ~/coding-challenge/admin/submit_early.sh";

export const getCloudShellSubmitCode = (): string =>
  "sh ~/coding-challenge/admin/submit.sh";

export const getCdIntoProjectDirectoryCode = ({
  projectDirectory,
}: M2InterviewScreenState): string =>
  `cd ~/coding-challenge/${projectDirectory}`;

export function getRunShFileNameCode({
  runShFileName,
}: M2InterviewScreenState): string {
  return runShFileName ? `sh ${runShFileName}` : "Loading...";
}

export function getM2DurationInMinutes(interview: Interview): number {
  return msToMin(getModuleDurationMs(interview, "m2"));
}

export function willPlayTenMinutesRemainingAudio(
  interview: Interview,
): boolean {
  return (
    getModuleDurationMs(interview, "m2") >
    moment.duration(10, "minutes").asMilliseconds()
  );
}

export function shouldShowServerInstructions(interview: Interview): boolean {
  return (
    isGenSweInterview(interview) ||
    isSREInterview(interview) ||
    isDataEngInterview(interview) ||
    isWebInterview(interview) ||
    isAppliedAIInterview(interview) ||
    isSecurityInterview(interview) ||
    isStaffEngInterview(interview)
  );
}

// [@Mason] Eventaully it might make sense to maintain a single source for all code snippets
// and commands in order to more easily manage them and ensure consistency/accuracy.
export const ctrlC = "ctrl + c";
