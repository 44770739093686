import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  createStyles,
  StyledComponentProps,
  withStyles,
} from "@material-ui/core/styles";
import type {Theme} from "@material-ui/core/styles/createTheme";
import Typography from "@material-ui/core/Typography";
import autobind from "autobind-decorator";
import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {submitM2} from "../actions/interviewActions";
import {toggleSubmittedDialog} from "../actions/m2InterviewScreenActions";
import {
  getMaxFileUploadSizeMB,
  toggleZipUploadDialog,
} from "../actions/zipUploadActions";
import {ExternalLink} from "../components/Link";
import {isMobileInterview, requiresNpmInstall} from "../helpers/interview";
import {AppState, Interview, ThunkDispatch, ZipUpload} from "../reducers/types";
import CodeSnippet from "./CodeSnippet";
import Note from "./Note";
import ZipUploader from "./ZipUploader";

interface Props extends StyledComponentProps, RouteComponentProps {
  interview: Interview;
  submitM2: (interview: Interview) => any;
  toggleSubmittedDialog: () => any;
  toggleZipUploadDialog: () => any;
  zipUpload: ZipUpload;
}

const styles = (theme: Theme) => createStyles({});

class ZipUploadDialog extends React.Component<Props> {
  @autobind
  async onSubmitUploadedCode() {
    const {interview} = this.props;
    await this.props.submitM2(interview);
    this.props.toggleSubmittedDialog();
  }

  @autobind
  onCancelSubmitCode() {
    this.props.toggleZipUploadDialog();
  }

  renderInstructions(): React.ReactNode {
    if (requiresNpmInstall(this.props.interview)) {
      return (
        <>
          <Typography paragraph={true}>
            Take 5 minutes to upload a zip file containing your implementation.
            To create the zip file, run the following command:
          </Typography>
          <CodeSnippet>npm run zip</CodeSnippet>
          <Typography paragraph={true}>
            This will create submission.zip, which has all of the project files,
            excluding the node_modules directory.
          </Typography>
        </>
      );
    } else if (
      isMobileInterview(this.props.interview) &&
      this.props.interview.language === "flutter"
    ) {
      return (
        <>
          <Typography paragraph={true}>
            Take 5 minutes to upload a zip file containing your implementation.
            Before creating your zip file please run the following command:
          </Typography>
          <CodeSnippet>flutter clean</CodeSnippet>
          <Typography paragraph={true}>
            Instructions for creating a zip file can be found{" "}
            <ExternalLink
              target="_blank"
              href="https://www.wikihow.com/Make-a-Zip-File"
            >
              here
            </ExternalLink>
            .
          </Typography>
        </>
      );
    } else if (this.props.interview.language === "rust") {
      return (
        <>
          <Typography paragraph={true}>
            Take 5 minutes to upload a zip file containing your implementation.
            Before creating your zip file please run the following command:
          </Typography>
          <CodeSnippet>cargo clean</CodeSnippet>
          <Typography paragraph={true}>
            Instructions for creating a zip file can be found{" "}
            <ExternalLink
              target="_blank"
              href="https://www.wikihow.com/Make-a-Zip-File"
            >
              here
            </ExternalLink>
            .
          </Typography>
        </>
      );
    } else {
      return (
        <Typography paragraph={true}>
          Take 5 minutes to upload a zip file containing your implementation.
          Instructions for creating a zip file can be found{" "}
          <ExternalLink
            target="_blank"
            href="https://www.wikihow.com/Make-a-Zip-File"
          >
            here
          </ExternalLink>
          .
        </Typography>
      );
    }
  }

  render() {
    const {zipUpload, interview} = this.props;
    const submitButtonText = interview.isSubmittingM2 ? "Submitting" : "Submit";
    return (
      <Dialog open={zipUpload.showDialog}>
        <DialogTitle>Upload your code</DialogTitle>
        <DialogContent>
          {this.renderInstructions()}
          <Note>
            <Typography>
              Please note, your zip file size must be less than{" "}
              {getMaxFileUploadSizeMB(interview)}MB.
            </Typography>
          </Note>
          <ZipUploader />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={!zipUpload.isUploaded || interview.isSubmittingM2}
            variant="contained"
            onClick={this.onSubmitUploadedCode}
          >
            {submitButtonText}
          </Button>
          <Button
            onClick={this.onCancelSubmitCode}
            disabled={interview.isSubmittingM2}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  interview: state.interview,
  zipUpload: state.zipUpload,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  submitM2: (interview: Interview) => {
    return dispatch(submitM2(interview));
  },
  toggleSubmittedDialog: () => dispatch(toggleSubmittedDialog()),
  toggleZipUploadDialog: () => dispatch(toggleZipUploadDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(ZipUploadDialog)));
