import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {
  StyledComponentProps,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import type {Theme} from "@material-ui/core/styles/createTheme";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ClassNameMap} from "@material-ui/styles";
import classNames from "classnames";
import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {submitSurvey} from "../actions/interviewSurveyScreenActions";
import CenteredScreenContent from "../components/CenteredScreenContent";
import {RedirectRule, withInterview} from "../components/InterviewLoader";
import Select from "../components/Select";
import Stars from "../components/Stars";
import {Option, genderOptions, raceOptions} from "../helpers/options";
import {thankYouUrl} from "../helpers/urls/routerUrls";
import {
  AppState,
  CandidateBBHCStatus,
  Interview,
  InterviewSurveyScreenState,
  ThunkDispatch,
} from "../reducers/types";
import {Gender, Race} from "../types/types";

interface Props extends StyledComponentProps {
  atsId: string;
  interviewSurveyScreenState: InterviewSurveyScreenState;
  submitSurvey: (
    interview: Interview,
    stars: number,
    feedback: string,
    gender: Gender,
    races: Race[],
  ) => any;
  interview: Interview;
  classes: Partial<ClassNameMap<string>>;
}

const selectWidth = 300;
const styles = (theme: Theme) =>
  createStyles({
    spacedText: {
      lineHeight: 1.5,
    },
    label: {
      fontWeight: "bold",
      fontSize: 16,
      width: 140,
    },
    select: {
      width: selectWidth,
    },
    autocomplete: {
      paddingRight: 5,
      width: selectWidth - 5,
    },
    autocompleteInput: {
      "& input": {
        marginLeft: 5,
        minHeight: 27,
        minWidth: 60,
        width: 0,
      },
    },
    helpIcon: {
      background: "white",
      borderRadius: "50%",
      height: 25,
      marginLeft: 10,
      width: 25,
    },
    helpIconText: {
      fontSize: 12,
    },
    surveyRow: {
      alignItems: "center",
      display: "flex",
      marginBottom: 25,
    },
    feedback: {
      marginBottom: 35,
    },
    stars: {
      marginBottom: 35,
    },
  });

function InterviewSurveyScreen(props: Props) {
  const [numStars, setNumStars] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");
  const [gender, setGender] = useState<Gender>(Gender.preferNotToSay);
  const [races, setRaces] = useState<Race[]>([]);
  const [racePlaceholder, setRacePlaceholder] = useState<string>("Select");

  function isSubmitEnabled(): boolean {
    return (
      !props.interviewSurveyScreenState.isSubmitting &&
      (numStars > 0 || feedback.length > 0)
    );
  }

  function onRaceChange(
    e: React.ChangeEvent<HTMLSelectElement>,
    selectedOptions: Option[],
  ) {
    setRacePlaceholder(selectedOptions.length > 0 ? "" : "Select");
    setRaces(selectedOptions.map((o) => o.value).sort() as Race[]);
  }

  async function submit() {
    await props.submitSurvey(
      props.interview,
      numStars,
      feedback,
      gender,
      races,
    );
  }

  const {interviewSurveyScreenState, classes, interview} = props;

  let heading = "";
  let placeholder = "";

  if (interview.bbhcStatus === CandidateBBHCStatus.optin) {
    heading =
      "Thank you for sharing your Byteboard interview materials! How would you rate this experience?";
    placeholder =
      "Add some written feedback about your experience with the Byteboard Hiring Consortium!";
  } else if (
    interview.bbhcStatus === CandidateBBHCStatus.optout &&
    !interview.m1OpenTime
  ) {
    heading = "Not interested in sharing your past materials? Let us know why.";
    placeholder = "We'd love to understand and appreciate your feedback!";
  } else {
    heading = "All done! Congratulations! How would you rate your experience?";
    placeholder =
      "Let us know what went well and what we could improve to make this interview experience better!";
  }

  let submitButtonText;

  if (interviewSurveyScreenState.isSubmitting) {
    submitButtonText = "Submitting";
  } else if (
    interview.bbhcStatus === CandidateBBHCStatus.optout &&
    !interview.m1OpenTime
  ) {
    submitButtonText = "Re-take the interview";
  } else {
    submitButtonText = "Submit";
  }

  return (
    <CenteredScreenContent>
      <Typography variant="h2">{heading}</Typography>
      {
        /* If the candidate opted-out and is submitting feedback,
            we don't need to collect a rating since they have not experienced
            the BBHC to be able to rate it. */
        interview.bbhcStatus === CandidateBBHCStatus.optout ? (
          <></>
        ) : (
          <div className={classes.stars}>
            <Stars numStars={numStars} onChange={(e) => setNumStars(e)} />
          </div>
        )
      }

      <div className={classes.feedback}>
        <TextField
          className={classes.spacedComponent}
          multiline={true}
          variant="outlined"
          fullWidth={true}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder={placeholder}
          rows={4}
          maxRows={15}
        />
      </div>

      <Typography variant="caption" paragraph={true}>
        Byteboard is committed to offering more equitable technical interviews.
        In order to understand our impact, we may - with your consent - collect
        and analyze demographic information to improve our experience as well as
        the fairness of our evaluation criteria. To assist in this effort,
        please consider self-identifying below. Again,{" "}
        <Box fontWeight="bold" display="inline">
          sharing your demographic information is optional.
        </Box>{" "}
        We will not use your demographic information, or your decision to not
        share demographic information, when evaluating your interview materials.
        This information is used solely to improve our interviewing platform and
        to share de-identified/aggregated insights with Byteboard's clients and
        those interested in learning more about Byteboard's approach to
        implementing equitable evaluation practices.
      </Typography>

      <div className={classes.surveyRow}>
        <Typography className={classNames(classes.label)}>Gender:</Typography>
        <Select
          onChange={(e) => setGender(e.target.value as Gender)}
          className={classes.select}
          value={gender}
          options={genderOptions}
          name="gender"
        />
      </div>

      <div className={classes.surveyRow}>
        <Typography className={classNames(classes.label)}>
          Race/Ethnicity:
        </Typography>
        <Autocomplete
          multiple
          options={raceOptions}
          disableCloseOnSelect
          className={classes.autocomplete}
          getOptionLabel={(option) => (option as any).shortLabel}
          onChange={onRaceChange}
          renderOption={(option, {selected}) => (
            <React.Fragment>
              <Checkbox style={{marginRight: 8}} checked={selected} />
              {option.label}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={racePlaceholder}
              fullWidth
              className={classes.autocompleteInput}
            />
          )}
        />
      </div>

      <Button
        color="primary"
        variant="contained"
        disabled={!isSubmitEnabled()}
        onClick={submit}
      >
        {submitButtonText}
      </Button>
    </CenteredScreenContent>
  );
}
class InterviewSurveyScreenWrapper extends React.Component<Props> {
  render() {
    return <InterviewSurveyScreen {...this.props} />;
  }
}

const mapStateToProps = (state: AppState) => ({
  interviewSurveyScreenState: state.interviewSurveyScreenState,
  interview: state.interview,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  submitSurvey: (
    interview: Interview,
    stars: number,
    feedback: string,
    gender: Gender,
    races: Race[],
  ) => {
    return dispatch(submitSurvey(interview, stars, feedback, gender, races));
  },
});

const redirectRules: RedirectRule[] = [
  {
    /* If the candidate has already completed the survey, redirect them to the thank you screen. */
    match: (interview: Interview) => interview.survey_stars !== undefined,
    redirect: (interview) => thankYouUrl(interview.id),
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withInterview(redirectRules, {ignoreGlobalRules: ["terms", "getStarted"]})(
      InterviewSurveyScreenWrapper,
    ),
  ),
);
