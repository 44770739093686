import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  User,
} from "firebase/auth";

export function getUser(): User | null {
  const auth = getAuth();
  return auth.currentUser;
}

export async function getUserIdToken(): Promise<string> {
  return (await getUser()?.getIdToken()) ?? "";
}

export async function signIn() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider).catch((err) => {
    if (err.code === "auth/popup-blocked") {
      throw new Error("Sign-in popup blocked.");
    }
    throw new Error(err);
  });
}

export async function signOut() {
  const auth = getAuth();
  await auth.signOut().catch(() => {
    throw new Error("Failed to sign out.");
  });
}
