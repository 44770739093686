import {Interview, ThunkDispatch} from "../reducers/types";
import {optinToBBHC, optoutOfBBHC} from "./bbhcActions";

export function saveBBHCOptin(interview: Interview) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    await dispatch(optinToBBHC(interview));
  };
}

export function saveBBHCOptout(interview: Interview) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    await dispatch(optoutOfBBHC(interview));
  };
}
