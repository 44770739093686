import {Interview, ThunkDispatch} from "../reducers/types";
import {Gender, Race} from "../types/types";
import {InterviewSurveyScreenAction} from "./actionTypes";
import {saveSurvey} from "./interviewActions";

export const SET_INTERVIEW_SURVEY_SCREEN_NUM_STARS =
  "SET_INTERVIEW_SURVEY_SCREEN_NUM_STARS";
export const IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN =
  "IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN";

export function isSubmitting(value: boolean): InterviewSurveyScreenAction {
  return {
    type: IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN,
    value,
  };
}

export function submitSurvey(
  interview: Interview,
  stars: number,
  feedback: string,
  gender: Gender,
  races: Race[],
) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    dispatch({
      type: IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN,
      value: true,
    });
    await dispatch(saveSurvey(interview, stars, feedback, gender, races));
    dispatch({
      type: IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN,
      value: false,
    });
  };
}
