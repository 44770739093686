import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {useSelector} from "react-redux";
import {getSupportEmail} from "../../../actions/interviewActions";
import CenteredScreenContent from "../../../components/CenteredScreenContent";
import {OpenCloudShellButton} from "../../../components/CloudShell";
import CodeSnippet from "../../../components/CodeSnippet";
import {instructionsTextStyle} from "../../../components/Instructions";
import M2SubmittedDialog from "../../../components/M2SubmittedDialog";
import {webInteviewPort} from "../../../config";
import {getUser} from "../../../helpers/auth";
import {
  getCdIntoProjectDirectoryCode,
  getCloudShellEarlySubmitCode,
  getCloudShellInterviewSetupCode,
  getCloudShellSubmitCode,
  getM2DurationInMinutes,
  getRunShFileNameCode,
  isModuleInProgress,
  isWebInterview,
  npmInstallCommand,
  npmStartCommand,
  requiresNpmInstall,
  willPlayTenMinutesRemainingAudio,
} from "../../../helpers/interview";
import webPreviewIcon from "../../../img/web-preview-icon.png"; // TODO make the correct img
import {
  AppState,
  Flags,
  Interview,
  M2InterviewScreenState,
} from "../../../reducers/types";
import {getServerTime} from "../../../util/time";
import Expectations from "../Expectations";
import EarlySubmitBanner from "./EarlySubmitBanner";
import CloudShellTips from "./Tips";

const useStyles = makeStyles({
  instructionsText: instructionsTextStyle,
  shellIcon: {
    margin: "0 2px",
    transform: "translateY(2px)",
    width: 18,
  },
});

interface Props {
  flags: Flags;
  interview: Interview;
  m2InterviewScreenState: M2InterviewScreenState;
}

export default function CloudShellInterviewInstructions({
  flags,
  interview,
  m2InterviewScreenState,
}: Props) {
  const classes = useStyles();
  const supportEmail = getSupportEmail();
  const serverTimeOffset = useSelector(
    (state: AppState) => state.serverInfo.serverTimeOffset,
  );
  const m2IsStarted = isModuleInProgress(
    "m2",
    interview,
    getServerTime(serverTimeOffset),
  );

  return (
    <CenteredScreenContent size="tall">
      {m2IsStarted && <EarlySubmitBanner />}
      <Typography variant="h3">Part 2 Instructions</Typography>
      <Typography paragraph={true}>
        Your Part 2 timer will not begin until you download your code into the
        Google Cloud Shell environment. When your timer begins, you will have{" "}
        {getM2DurationInMinutes(interview)} minutes to work on the
        implementation.
      </Typography>
      <Typography paragraph={true}>
        You will be able to come back to this page at any time to view your
        timer. Additionally, you will hear a notification{" "}
        {willPlayTenMinutesRemainingAudio(interview)
          ? "when 10 minutes remain, and again "
          : ""}
        when the implementation time expires. After that, your code will be
        auto-submitted by Cloud Shell. Turn on your sound now to ensure you hear
        the notification
        {willPlayTenMinutesRemainingAudio(interview) ? "s" : ""}.
      </Typography>
      <Expectations flags={flags} interview={interview} />
      <Typography variant="h4">Step 1</Typography>
      <Typography paragraph={true}>
        If you have closed it, click the button below to open Google Cloud
        Shell.
      </Typography>
      <Typography paragraph={true}>
        <strong>Important:</strong> Google Cloud Shell may not work correctly if
        you have third-party cookies disabled or are in private browsing mode.
        If you encounter issues, try enabling third-party cookies, switching to
        standard browsing mode, or using a different browser.
      </Typography>
      <OpenCloudShellButton authUserEmail={getUser()?.email ?? ""} />
      <br />
      <br />
      <Typography variant="h4">Step 2</Typography>
      <Typography className={classes.instructionsText} paragraph={true}>
        Once the editor is loaded, copy the text below, paste it into the
        command line, and push enter to run it.{" "}
        <strong>This will begin your timer.</strong>
      </Typography>
      <CodeSnippet>{getCloudShellInterviewSetupCode(interview)}</CodeSnippet>
      <Typography variant="h4">Step 3</Typography>
      <Typography className={classes.instructionsText} paragraph={true}>
        Move into the project directory by running
      </Typography>
      <CodeSnippet>
        {getCdIntoProjectDirectoryCode(m2InterviewScreenState)}
      </CodeSnippet>
      {isWebInterview(interview) ? (
        <>
          <Typography className={classes.instructionsText} paragraph={true}>
            Install the dependencies by running
          </Typography>
          <CodeSnippet>{npmInstallCommand}</CodeSnippet>
          <Typography className={classes.instructionsText} paragraph={true}>
            Start your server by running
          </Typography>
          <CodeSnippet>{npmStartCommand}</CodeSnippet>
          <Typography className={classes.instructionsText} paragraph={true}>
            Click the <img src={webPreviewIcon} className={classes.shellIcon} />{" "}
            icon at the top right of the editor and press "Preview on port{" "}
            {webInteviewPort}" in the dropdown menu. A new page should open that
            contains a preview of your web app. Note: You will need to refresh
            the preview page whenever you make a change to your code.
          </Typography>
        </>
      ) : (
        <>
          {requiresNpmInstall(interview) && (
            <>
              <Typography className={classes.instructionsText} paragraph={true}>
                Install the dependencies by running
              </Typography>
              <CodeSnippet>{npmInstallCommand}</CodeSnippet>
            </>
          )}
          <Typography className={classes.instructionsText} paragraph={true}>
            Run the server by running
          </Typography>
          <CodeSnippet>
            {getRunShFileNameCode(m2InterviewScreenState)}
          </CodeSnippet>
          <Typography className={classes.instructionsText} paragraph={true}>
            You should see output that confirms the server runs. You can rerun
            the script each time you want to verify your code.
          </Typography>
        </>
      )}
      <Typography variant="h4">Step 4</Typography>
      <Typography className={classes.instructionsText} paragraph={true}>
        Go through the list of tasks in the README file and complete them. Tip:
        You can place your README file side-by-side with other files by dragging
        files to your desired configuration in the editor.
      </Typography>
      <Typography variant="h4">Step 5</Typography>
      <Typography className={classes.instructionsText} paragraph={true}>
        Your code will automatically submit at the end of your time, provided
        that Cloud Shell is open. If you would like to submit early, run the
        command below. Note that submitting will end your interview and cannot
        be undone.
      </Typography>
      <CodeSnippet>{getCloudShellEarlySubmitCode()}</CodeSnippet>
      <Typography className={classes.instructionsText} paragraph={true}>
        If for some reason your code does not submit, please submit by running{" "}
      </Typography>
      <CodeSnippet>{getCloudShellSubmitCode()}</CodeSnippet>
      <Typography>
        If for some reason your code still does not submit, please download your
        code by right clicking on the folder in the Cloud Shell editor and email
        it to {supportEmail}.
      </Typography>
      <br />
      <CloudShellTips interview={interview} />
      <M2SubmittedDialog show={Boolean(interview.m2EndTime)} />
    </CenteredScreenContent>
  );
}
