import {Interview, ThunkDispatch} from "../reducers/types";
import {saveTerms as saveInterviewTerms} from "./interviewActions";

export function saveTerms(interview: Interview) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    // Wait before changing the state so the button doesn't flash.
    await new Promise((resolve) => setTimeout(resolve, 100));
    await dispatch(saveInterviewTerms(interview));
  };
}
