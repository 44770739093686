import Button from "@material-ui/core/Button";
import {
  createStyles,
  StyledComponentProps,
  withStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import autobind from "autobind-decorator";
import {truncate} from "lodash";
import * as React from "react";
import {connect, useDispatch} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {
  createComment,
  deleteComment,
  loadBBDoc,
  updateCandidateResponse,
} from "../actions/bbdocActions";
import {showHelpDialog} from "../actions/helpDialogActions";
import {submitM1} from "../actions/interviewActions";
import * as m1InterviewScreenActions from "../actions/m1InterviewScreenActions";
import {LogAction, logFEInfo} from "../actions/serverInfoActions";
import {BBDoc} from "../components/BBDoc";
import ConfirmSubmitDialog from "../components/ConfirmSubmitDialog";
import Fade from "../components/Fade";
import {HelpTopicId} from "../components/HelpDialog";
import Instructions, {InstructionItem} from "../components/Instructions";
import {RedirectRule, withInterview} from "../components/InterviewLoader";
import Note from "../components/Note";
import Spinner from "../components/Spinner";
import SubmittedDialog from "../components/SubmittedDialog";
import UnorderedList from "../components/UnorderedList";
import {getUser} from "../helpers/auth";
import {generateBBDocCommentId, isBBDocEnabled} from "../helpers/bbdoc";
import {
  getIframeUrl,
  getModuleDurationMs,
  isDataAnalysisInterview,
  isSecurityInterview,
  isStaffEngInterview,
} from "../helpers/interview";
import {overviewUrl} from "../helpers/urls/routerUrls";
import {useEventListener} from "../hooks/browser";
import blurryDocImg from "../img/blurry-doc.png";
import {
  AppState,
  BBDocState,
  CandidateResponse,
  Flags,
  Interview,
  M1InterviewScreenState,
  ModuleId,
  ThunkDispatch,
} from "../reducers/types";
import {getServerTime as getServerTimeFn} from "../util/time";

const {
  toggleScreenInstructions,
  toggleConfirmDialog,
  toggleSubmittedDialog,
  startM1,
  readyToStartM1,
} = m1InterviewScreenActions;

const endTimeRedirectBuffer = 30 * 1000; // 30 seconds;
const autoCollapseMaxWidth = 1400;

const M1_MODULE_ID: ModuleId = "m1";

interface StateProps {
  interview: Interview;
  m1InterviewScreenState: M1InterviewScreenState;
  flags: Flags;
  bbdocState: BBDocState;
  getServerTime: () => number;
}
interface DispatchProps {
  loadBBDoc: typeof loadBBDoc;
  createBBDocComment: (
    candidateResponse: CandidateResponse,
  ) => ReturnType<typeof createComment>;
  deleteBBDocComment: (
    candidateResponse: CandidateResponse,
  ) => ReturnType<typeof deleteComment>;
  updateBBDocCandidateResponse: (
    candidateResponse: CandidateResponse,
  ) => ReturnType<typeof updateCandidateResponse>;
  logFEInfo: typeof logFEInfo;
  readyToStartM1: () => any;
  startM1: typeof startM1;
  submitM1: typeof submitM1;
  toggleScreenInstructions: () => any;
  toggleConfirmDialog: () => any;
  toggleSubmittedDialog: () => any;
  showHelpDialog: (activeTopicId: string) => void;
}
interface Props
  extends StateProps,
    DispatchProps,
    StyledComponentProps,
    RouteComponentProps {
  atsId: string;
}

const styles = () =>
  createStyles({
    root: {
      bottom: 0,
      display: "flex",
      left: 0,
      overflow: "hidden",
      position: "absolute",
      right: 0,
      top: 0,
    },
    contentContainer: {
      borderRight: "solid 1px #ddd",
      flex: 1,
      height: "100%",
      overflow: "hidden",
      position: "relative",
    },
    fullScreen: {
      height: "100%",
      width: "100%",
    },
    beforeInstructionsBackground: {
      background: `#fcfcfc url(${blurryDocImg}) no-repeat left top`,
      backgroundSize: 1300,
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    beforeInstructions: {
      background: "#fff",
      border: "solid 1px #ccc",
      borderRadius: 6,
      left: "50%",
      maxWidth: "80%",
      padding: "30px 25px 25px 25px",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
    },
    startButtonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    instructionsText: {
      color: "black",
    },
    list: {
      paddingLeft: 15,
    },
  });

class M1InterviewScreen extends React.Component<Props> {
  instructionsScrollEl?: HTMLElement;
  redirectTimeoutId: any;
  autoSubmitTimeoutId: any;
  beforeInstructionsRef: React.RefObject<HTMLSpanElement> = React.createRef();

  componentDidMount() {
    this.maybeFocusBeforeInstructions();
    this.autoSubmitWhenTimeExpires();
    this.maybeLoadBBDoc();
  }

  componentDidUpdate(prevProps: Props) {
    const {interview, m1InterviewScreenState} = this.props;
    if (
      (interview.m1DocId ||
        (this.shouldUseBBDoc() && interview.isBBDocReady)) &&
      m1InterviewScreenState.isStartingM1 &&
      !interview.m1OpenTime
    ) {
      this.props.startM1();
    }
    const justStarted = interview.m1OpenTime && !prevProps.interview.m1OpenTime;
    if (
      justStarted &&
      m1InterviewScreenState.hasExpandedInstructions &&
      window.innerWidth <= autoCollapseMaxWidth
    ) {
      this.onToggleExpandInstructions();
      this.props.logFEInfo(LogAction.AUTO_COLLAPSED_M1_INSTRUCTIONS, {
        autoCollapsedM1Instructions: true,
        screenWidth: window.innerWidth,
      });
    }

    // reset the autosubmit callback timeline every time we get new props,
    // to ensure that the autosubmit always triggers at the correct time.
    // (for example, if the support tool adds time to an active interview,
    // this will ensure the autosubmit doesn't trigger until the new timeline
    // is reached).
    this.autoSubmitWhenTimeExpires();

    this.maybeLoadBBDoc();
  }

  componentWillUnmount() {
    clearTimeout(this.redirectTimeoutId);
    clearTimeout(this.autoSubmitTimeoutId);
  }

  hasStarted(): boolean {
    return Boolean(this.getExpectedEndTime());
  }

  shouldUseBBDoc(): boolean {
    const {interview, flags} = this.props;
    return isBBDocEnabled(interview, flags);
  }

  getExpectedEndTime(): number {
    const {m1OpenTime, m1Duration} = this.props.interview;
    return m1OpenTime && m1Duration ? m1OpenTime + m1Duration : 0;
  }

  getM1DurationMinutes(): number {
    const m1Duration = getModuleDurationMs(this.props.interview, "m1");
    return m1Duration / 1000 / 60;
  }

  getTimeRemaining(): number {
    return this.getExpectedEndTime() - this.props.getServerTime();
  }

  isTimeExpired(): boolean {
    return this.getTimeRemaining() <= 0;
  }

  isEndTimeRecorded(): boolean {
    return Boolean(this.props.interview.m1EndTime);
  }

  isModuleComplete(): boolean {
    return this.isTimeExpired() || this.isEndTimeRecorded();
  }

  maybeFocusBeforeInstructions() {
    if (!this.hasStarted()) {
      this.beforeInstructionsRef.current?.focus();
    }
  }

  maybeLoadBBDoc() {
    const {isLoading, isLoaded} = this.props.bbdocState;
    if (this.hasStarted() && this.shouldUseBBDoc() && !isLoading && !isLoaded) {
      this.props.loadBBDoc(M1_MODULE_ID);
    }
  }

  autoSubmitWhenTimeExpires() {
    if (!this.hasStarted() || this.isModuleComplete()) {
      return;
    }

    let timeRemaining = this.getTimeRemaining();
    if (timeRemaining < 0) {
      timeRemaining = 0;
    }

    clearTimeout(this.autoSubmitTimeoutId);
    // even if timeRemaining == 0, we still set this to run in a 0-second
    // setTimeout, so the current execution context
    // (probably a componentDidUpdate) finishes before onSubmit runs.
    this.autoSubmitTimeoutId = setTimeout(() => {
      this.onSubmit();
    }, timeRemaining);
  }

  @autobind
  onToggleExpandInstructions() {
    this.props.toggleScreenInstructions();
  }

  @autobind
  onStart() {
    this.props.readyToStartM1();
  }

  @autobind
  onToggleConfirmDialog() {
    this.props.toggleConfirmDialog();
  }

  @autobind
  async onSubmit() {
    const {interview} = this.props;
    if (!this.isEndTimeRecorded()) {
      await this.props.submitM1(interview);
    }
    this.props.toggleSubmittedDialog();
  }

  @autobind
  onNextScreen() {
    const {atsId} = this.props;
    this.props.history.push(overviewUrl(atsId));
  }

  @autobind
  onOpenTips() {
    this.props.showHelpDialog(HelpTopicId.TIPS);
  }

  @autobind
  onNewComment(newComment: CandidateResponse) {
    // Generate a new id for the comment using the ats id. We need to do this
    // now instead of using the firestore auto id to ensure the animation will
    // be smooth.
    const id = generateBBDocCommentId();
    this.props.createBBDocComment({
      ...newComment,
      id,
    });
  }

  renderBeforeInstructions(): React.ReactNode {
    const {classes, m1InterviewScreenState} = this.props;
    const startButtonLabel = m1InterviewScreenState.isStartingM1
      ? "Loading"
      : "Start Part 1";
    return (
      <Fade in={!this.hasStarted()}>
        <div className={classes?.beforeInstructionsBackground}>
          <div className={classes?.beforeInstructions}>
            <Typography variant="h2">
              <span
                tabIndex={-1}
                style={{outline: "none"}}
                ref={this.beforeInstructionsRef}
              >
                Before we begin:
              </span>
            </Typography>
            <UnorderedList
              items={[
                <Typography className={classes?.instructionsText}>
                  In the top right corner of the page, note the box that says
                  "Time has not begun." This is your timer. It can be
                  temporarily hidden by clicking on it.
                </Typography>,
                <Typography className={classes?.instructionsText}>
                  The use of copy/paste has been disabled for Part 1. Please be
                  mindful of where you are leaving your input in order to
                  minimize lost time.
                </Typography>,
                <Typography className={classes?.instructionsText}>
                  On the right are your instructions. These will be present
                  throughout Part 1 and can be collapsed using the button at the
                  bottom left of the panel. Please read them now, before
                  proceeding.
                </Typography>,
              ]}
            />
            <div className={classes?.startButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                disabled={m1InterviewScreenState.isStartingM1}
                onClick={this.onStart}
              >
                {startButtonLabel}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    );
  }

  renderInstructions(): React.ReactNode {
    const {interview, classes, m1InterviewScreenState} = this.props;
    const {hasExpandedInstructions} = m1InterviewScreenState;
    const instructions: InstructionItem[] = [
      {
        enabled: true,
        content: (
          <React.Fragment>
            <Typography variant="h3" align="center">
              Part 1 Instructions
            </Typography>
            <Typography className={classes?.instructionsText}>
              Your teammates have started to outline the design for a new
              project and they need your help to complete it. In the next{" "}
              {this.getM1DurationMinutes()} minutes, your job is to finish the
              design document by taking the following steps:
            </Typography>
            <br />
            <Typography variant="h4">Step 1</Typography>
            <Typography className={classes?.instructionsText}>
              Read the document carefully to understand the goals of the project
              and its technical design. You are encouraged to leave comments
              with questions or clarifications you may have.
            </Typography>
            <br />
            <Typography variant="h4">Step 2</Typography>
            <Typography className={classes?.instructionsText}>
              Reply to the questions left as comments on the right side of the
              document.
            </Typography>
            <br />
            <Typography className={classes?.instructionsText}>
              For questions that ask you to make a recommendation, there is no
              "right answer"; however, we recommend discussing the pros, cons,
              trade-offs, and assumptions that motivated your choice.
            </Typography>
            <br />
            {this.shouldUseBBDoc() ? null : (
              <Note>
                <Typography className={classes?.instructionsText}>
                  Note: If you are having trouble viewing or accessing comments,
                  you can answer the comment prompts inline at the bottom of the
                  document. Comments are hyperlinked (blue underlined text), and
                  you can click to see the associated prompt.
                </Typography>
              </Note>
            )}
            <Typography className={classes?.instructionsText}>
              If you run into any issues or have questions, click the{" "}
              <strong>?</strong> at the top of your screen.
            </Typography>
          </React.Fragment>
        ),
      },
    ];
    return (
      <Instructions
        instructions={instructions}
        instructionsIndex={0}
        isExpanded={hasExpandedInstructions}
        isSubmitEnabled={this.hasStarted()}
        submitButtonLabel="Submit"
        onToggleExpand={this.onToggleExpandInstructions}
        onSubmit={this.onToggleConfirmDialog}
        onNext={() => {
          /**/
        }}
        onPrev={() => {
          /**/
        }}
      />
    );
  }

  renderDoc(): React.ReactNode {
    if (this.shouldUseBBDoc()) {
      return this.renderBBDoc();
    }
    return this.renderIframe();
  }

  renderBBDoc(): React.ReactNode {
    const {interview} = this.props;
    const {interviewDoc, isLoaded, isLoading} = this.props.bbdocState;
    if (isLoaded && interviewDoc) {
      return (
        <BBDoc
          interviewDoc={interviewDoc}
          readonly={this.isModuleComplete()}
          candidateName={interview.candidateName}
          onUpdateCandidateResponse={this.props.updateBBDocCandidateResponse}
          onNewComment={this.onNewComment}
          onDeleteComment={this.props.deleteBBDocComment}
        />
      );
    } else if (isLoading) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  renderIframe(): React.ReactNode {
    const {interview, m1InterviewScreenState, classes} = this.props;
    const {m1DocId, m1DocUrl, m1OpenTime} = interview;
    const time = m1InterviewScreenState.docUpdateTime;
    const iframeSrc = getIframeUrl(m1DocId, m1DocUrl, {
      rm: "minimal",
      time: time.toString(),
      authuser: getUser()?.email ?? "",
    });
    if (iframeSrc && Boolean(m1OpenTime)) {
      return (
        <iframe
          src={iframeSrc}
          className={classes?.fullScreen}
          frameBorder={0}
          marginHeight={0}
          marginWidth={0}
        />
      );
    } else if (this.hasStarted()) {
      return <Spinner />;
    }
    return null;
  }

  renderConfirmSubmitDialog(): React.ReactNode {
    const {m1InterviewScreenState} = this.props;
    const {showConfirmDialog, isSubmittingM1} = m1InterviewScreenState;
    const confirmSubmitButtonLabel = isSubmittingM1
      ? "Submitting"
      : "Submit Part 1";
    return (
      <ConfirmSubmitDialog
        label={confirmSubmitButtonLabel}
        show={showConfirmDialog}
        isSubmitting={isSubmittingM1}
        onConfirmSubmit={this.onSubmit}
        onCancelSubmit={this.onToggleConfirmDialog}
      />
    );
  }

  renderSubmittedDialog(): React.ReactNode {
    const {interview, m1InterviewScreenState} = this.props;
    const breakDuration = getModuleDurationMs(interview, "break");
    const {showSubmittedDialog, isSubmittingM1} = m1InterviewScreenState;
    const breakDurationMinutes = breakDuration / 1000 / 60;
    return (
      <SubmittedDialog
        header="Part 1 has been submitted!"
        text={`You will now have a ${breakDurationMinutes} minute break (see top-right timer). At the end of your break you should start Part 2 of the interview.`}
        buttonLabel="I got it!"
        show={showSubmittedDialog}
        isSubmitting={isSubmittingM1}
        onNextScreen={this.onNextScreen}
      />
    );
  }

  render(): React.ReactNode {
    const {classes} = this.props;
    return (
      <WrapClipboard>
        <div className={classes?.root}>
          <div className={classes?.contentContainer}>
            {this.renderBeforeInstructions()}
            {this.renderDoc()}
          </div>
          {this.renderInstructions()}
          {this.renderConfirmSubmitDialog()}
          {this.renderSubmittedDialog()}
        </div>
      </WrapClipboard>
    );
  }
}

function WrapClipboard(props: {children: React.ReactNode}): React.ReactElement {
  const maxLength = 100; // limit how much text we put into the logs
  const dispatch = useDispatch();

  useEventListener("copy", (e: ClipboardEvent) => {
    const text = document.getSelection()?.toString();
    if (text) {
      logAction(LogAction.COPIED_M1_CONTENT, text);
    }
  });

  useEventListener("paste", (e: ClipboardEvent) => {
    const text = e.clipboardData?.getData("text");
    if (text) {
      logAction(LogAction.PASTED_M1_CONTENT, text);
    }
  });

  function logAction(action: LogAction, text: string) {
    dispatch(
      logFEInfo(action, {
        text: truncate(text, {length: maxLength}),
        length: text.length,
      }),
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}

const mapStateToProps = (state: AppState): StateProps => ({
  interview: state.interview,
  m1InterviewScreenState: state.m1InterviewScreenState,
  flags: state.flags,
  bbdocState: state.bbdocState.current,
  getServerTime: () => getServerTimeFn(state.serverInfo.serverTimeOffset),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  loadBBDoc: (moduleId: ModuleId) => dispatch(loadBBDoc(moduleId)),
  createBBDocComment: (candidateResponse: CandidateResponse) => {
    return dispatch(createComment(candidateResponse, M1_MODULE_ID));
  },
  deleteBBDocComment: (candidateResponse: CandidateResponse) => {
    return dispatch(deleteComment(candidateResponse));
  },
  logFEInfo: (action: LogAction, data: any) => {
    return dispatch(logFEInfo(action, data));
  },
  readyToStartM1: () => dispatch(readyToStartM1()),
  startM1: () => dispatch(startM1()),
  submitM1: (interview: Interview) => {
    return dispatch(submitM1(interview));
  },
  toggleScreenInstructions: () => dispatch(toggleScreenInstructions()),
  toggleConfirmDialog: () => dispatch(toggleConfirmDialog()),
  toggleSubmittedDialog: () => dispatch(toggleSubmittedDialog()),
  showHelpDialog: (activeTopicId: string) => {
    return dispatch(showHelpDialog(activeTopicId));
  },
  updateBBDocCandidateResponse: (candidateResponse: CandidateResponse) => {
    return dispatch(updateCandidateResponse(candidateResponse, M1_MODULE_ID));
  },
});

const redirectRules: RedirectRule[] = [
  {
    match: (interview: Interview, serverTime: number) => {
      return !!(
        interview.m1EndTime &&
        interview.m1EndTime + endTimeRedirectBuffer < serverTime
      );
    },
    redirect: (interview) => overviewUrl(interview.id),
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withInterview(redirectRules)(withRouter(M1InterviewScreen)),
  ),
);
