import * as React from "react";
import {ErrorAction} from "./actionTypes";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

export class UnexpectedServerResponse {
  response: any;
  message: string;
  constructor(response: any) {
    this.response = response;
    this.message = `Received unexpected response: ${JSON.stringify(response)}`;
  }
}

export interface ErrorMessageOptions {
  /** Optional additional tips to show the user for the current error. */
  tips?: React.ReactNode[];
  /** Whether or not to hide all of the tips from the error screen. */
  hideTips?: boolean;
  /** Whether or not this should display as a notification. */
  isNotification?: boolean;
}

export function setErrorMessage(
  errorMessage: string,
  opts: ErrorMessageOptions = {},
): ErrorAction {
  return {
    errorMessage,
    tips: opts.tips || [],
    hideTips: opts.hideTips || false,
    isNotification: opts.isNotification || false,
    type: SET_ERROR_MESSAGE,
  };
}
