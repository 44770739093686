import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {getUserIdToken} from "../helpers/auth";
import {
  hasModifiedM2CodeUrl,
  m2AdditionalTimeDescriptionUrl,
  m2BaseZipDownloadUrl,
  m2CandidateZipDownloadUrl,
  m2OpenTimeUrl,
  projectNameUrl,
  runShFileNameUrl,
  updateInterviewUrl,
} from "../helpers/urls/interviewUrls";
import {AppState, M2Type, ZipDownloadType} from "../reducers/types";
import {getBrowserName, getOS} from "../util/browser";
import {get, post} from "../util/fetch";
import {
  ErrorAction,
  InterviewAction,
  M2InterviewScreenAction,
} from "./actionTypes";
import {setErrorMessage} from "./errorActions";
import {hideHelpDialog} from "./helpDialogActions";
import {LogAction, logFEInfo} from "./serverInfoActions";
import {toast} from "./toastActions";

type Action = M2InterviewScreenAction | InterviewAction | ErrorAction;
type AsyncAction = ThunkAction<
  Promise<void>,
  {},
  Record<string, never>,
  Action
>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

const downloadDelay = 2000;

export const IS_STARTING_M2 = "IS_STARTING_M2";
export const IS_SUBMITTING_M2 = "IS_SUBMITTING_M2";
export const IS_DOWNLOADING_ZIP = "IS_DOWNLOADING_ZIP";
export const IS_SCREEN_OPEN = "IS_SCREEN_OPEN";
export const TOGGLE_M2_INTERVIEW_SCREEN_INSTRUCTIONS =
  "TOGGLE_M2_INTERVIEW_SCREEN_INSTRUCTIONS";
export const TOGGLE_M2_INTERVIEW_SCREEN_CONFIRM_DIALOG =
  "TOGGLE_M2_INTERVIEW_SCREEN_CONFIRM_DIALOG";
export const TOGGLE_M2_INTERVIEW_SCREEN_SUBMITTED_DIALOG =
  "TOGGLE_M2_INTERVIEW_SCREEN_SUBMITTED_DIALOG";
export const SET_M2_INTERVIEW_SCREEN_INSTRUCTIONS_INDEX =
  "SET_M2_INTERVIEW_SCREEN_INSTRUCTIONS_INDEX";
export const SET_M2_INTERVIEW_SCREEN_TASK_INDEX =
  "SET_M2_INTERVIEW_SCREEN_TASK_INDEX";
export const SET_M2_TASK_LIST = "SET_M2_TASK_LIST";
export const SET_M2_RUN_SH_FILE_NAME = "SET_M2_RUN_SH_FILE_NAME";
export const SET_M2_PROJECT_DIRECTORY = "SET_M2_PROJECT_DIRECTORY";
export const SET_M2_PROJECT_NAME = "SET_M2_PROJECT_NAME";
export const IS_SUBMITTING_M2_ADDITIONAL_TIME_DESCRIPTION =
  "IS_SUBMITTING_M2_ADDITIONAL_TIME_DESCRIPTION";
export const IS_UPDATING_M2_TYPE = "IS_UPDATING_M2_TYPE";
export const SET_HAS_MODIFIED_M2_CODE = "SET_HAS_MODIFIED_M2_CODE";
export const SET_ZIP_DOWNLOAD_TYPE = "SET_ZIP_DOWNLOAD_TYPE";
export const SET_SWITCHED_TO_CLOUDSHELL_FROM_ZIP =
  "SET_SWITCHED_TO_CLOUDSHELL_FROM_ZIP";

export function isStartingM2(value: boolean): M2InterviewScreenAction {
  return {
    type: IS_STARTING_M2,
    value,
  };
}

export function isSubmittingM2(value: boolean): M2InterviewScreenAction {
  return {
    type: IS_SUBMITTING_M2,
    value,
  };
}

export function isDownloadingZip(value: boolean): M2InterviewScreenAction {
  return {
    type: IS_DOWNLOADING_ZIP,
    value,
  };
}

export function isScreenOpen(value: boolean): M2InterviewScreenAction {
  return {
    type: IS_SCREEN_OPEN,
    value,
  };
}

export function toggleScreenInstructions(): M2InterviewScreenAction {
  return {
    type: TOGGLE_M2_INTERVIEW_SCREEN_INSTRUCTIONS,
  };
}

export function toggleConfirmDialog(): M2InterviewScreenAction {
  return {
    type: TOGGLE_M2_INTERVIEW_SCREEN_CONFIRM_DIALOG,
  };
}

export function toggleSubmittedDialog(): M2InterviewScreenAction {
  return {
    type: TOGGLE_M2_INTERVIEW_SCREEN_SUBMITTED_DIALOG,
  };
}

export function setInstructionsIndex(value: number): M2InterviewScreenAction {
  return {
    type: SET_M2_INTERVIEW_SCREEN_INSTRUCTIONS_INDEX,
    value,
  };
}

export function setM2RunShFileName(value: string): M2InterviewScreenAction {
  return {
    type: SET_M2_RUN_SH_FILE_NAME,
    value,
  };
}

export function setM2ProjectDirectory(value: string): M2InterviewScreenAction {
  return {
    type: SET_M2_PROJECT_DIRECTORY,
    value,
  };
}

export function setM2ProjectName(value: string): M2InterviewScreenAction {
  return {
    type: SET_M2_PROJECT_NAME,
    value,
  };
}

export function setZipDownloadType(
  value: ZipDownloadType,
): M2InterviewScreenAction {
  return {
    type: SET_ZIP_DOWNLOAD_TYPE,
    value,
  };
}

export function setSwitchedToCloudshellFromZip(): M2InterviewScreenAction {
  return {
    type: SET_SWITCHED_TO_CLOUDSHELL_FROM_ZIP,
  };
}

function isSubmittingM2AdditionalTimeDescription(
  value: boolean,
): M2InterviewScreenAction {
  return {
    type: IS_SUBMITTING_M2_ADDITIONAL_TIME_DESCRIPTION,
    value,
  };
}

function isUpdatingM2Type(value: boolean): M2InterviewScreenAction {
  return {
    type: IS_UPDATING_M2_TYPE,
    value,
  };
}

function setHasModifiedM2Code(value: boolean): M2InterviewScreenAction {
  return {
    type: SET_HAS_MODIFIED_M2_CODE,
    value,
  };
}

export function downloadZip(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview, m2InterviewScreenState} = getState();
    const {zipDownloadType} = m2InterviewScreenState;
    try {
      dispatch(isDownloadingZip(true));
      const url =
        zipDownloadType === "base"
          ? m2BaseZipDownloadUrl(interview.id)
          : m2CandidateZipDownloadUrl(interview.id);
      window.location.assign(url);
      // Emulate async download
      await new Promise((resolve) => setTimeout(resolve, downloadDelay));
      dispatch(isDownloadingZip(false));
    } catch (err) {
      console.error("Error downloading interview materials:", err);
      dispatch(setErrorMessage("Unable to download interview materials."));
    }
  };
}

export function fetchRunShFileName(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      const res = await get(runShFileNameUrl(interview.id));
      const json = await res.json();
      const {error, runShFileName} = json;
      if (error) {
        console.error("Error fetching run sh file name:", error);
        dispatch(setErrorMessage("Unable to load interview materials."));
      } else {
        dispatch(setM2RunShFileName(runShFileName));
      }
    } catch (err) {
      console.error("Error while fetching run sh file name:", err);
      dispatch(setErrorMessage("Failed to connect to the back end."));
    }
  };
}

export function fetchProjectDirectory(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(setM2ProjectDirectory("code"));
  };
}

export function fetchProjectName(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      const res = await get(projectNameUrl(interview.id));
      const json = await res.json();
      const {error, projectName} = json;
      if (error) {
        console.error("Error fetching project name:", error);
        dispatch(setErrorMessage("Unable to load interview materials."));
      } else {
        dispatch(setM2ProjectName(projectName));
      }
    } catch (err) {
      console.error("Error while fetching project name:", err);
      dispatch(setErrorMessage("Failed to connect to the back end."));
    }
  };
}

export function fetchHasModifiedM2Code(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      const res = await get(hasModifiedM2CodeUrl(interview.id));
      const json = await res.json();
      const {error, hasCandidateModifiedM2Code} = json;
      if (error) {
        throw error;
      } else {
        dispatch(setHasModifiedM2Code(hasCandidateModifiedM2Code));
      }
    } catch (err) {
      console.error(err);
      dispatch(toast("Failed to load config."));
    }
  };
}

export function startM2(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      if (interview.m2OpenTime) {
        console.info("M2 is already open");
        return;
      }
      const data = {atsId: interview.id};
      const response = await post(m2OpenTimeUrl(), data, {
        token: await getUserIdToken(),
      });
      const {error} = await response.json();
      if (error) {
        console.error("Error starting m2:", error);
        dispatch(setErrorMessage("Unable to start interview."));
      }
    } catch (err) {
      console.error("Error starting m2", err);
      dispatch(setErrorMessage("Unable to start interview."));
    }
  };
}

export function saveM2AdditionalTimeDescription(
  text: string,
): ThunkAction<Promise<void>, {}, {}, M2InterviewScreenAction> {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      if (!text) return;
      dispatch(isSubmittingM2AdditionalTimeDescription(true));
      const {interview} = getState();
      const data = {text};
      await post(m2AdditionalTimeDescriptionUrl(interview.id), data, {
        token: await getUserIdToken(),
      });
      dispatch(isSubmittingM2AdditionalTimeDescription(false));
    } catch (err) {
      // Go ahead and swallow the error; the front-end doesn't need to be
      // blocked if we get an error here.
      console.log("Unable to save m2 additional time description.", err);
      dispatch(isSubmittingM2AdditionalTimeDescription(false));
    }
  };
}

export function updateM2Type(m2Type: M2Type): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview, m2InterviewScreenState} = getState();
      if (m2InterviewScreenState.isUpdatingM2Type) return;
      dispatch(isUpdatingM2Type(true));
      const updates = {m2Type};
      const response = await post(
        updateInterviewUrl(interview.id),
        {updates},
        {token: await getUserIdToken()},
      );
      const {error} = await response.json();
      if (error) {
        throw error;
      } else {
        dispatch(hideHelpDialog());
        const data = {os: getOS(), browser: getBrowserName()};
        if (m2Type === "zip") {
          dispatch(logFEInfo(LogAction.SWITCHED_TO_ZIP, data));
        } else if (m2Type === "cloudshell") {
          dispatch(logFEInfo(LogAction.SWITCHED_TO_CLOUDSHELL, data));
        }
      }
    } catch (err) {
      console.error("Error updating m2 type", err);
      dispatch(toast("Error: Unable to update interview."));
    }
    dispatch(isUpdatingM2Type(false));
  };
}
