import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  StyledComponentProps,
  withStyles,
} from "@material-ui/core/styles";
import type {Theme} from "@material-ui/core/styles/createTheme";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import autobind from "autobind-decorator";
import classNames from "classnames";
import * as React from "react";
import {connect} from "react-redux";
import {
  clearUploadedZipFile,
  uploadZipFile,
  ZIP,
} from "../actions/zipUploadActions";
import {
  AppState,
  FileDiff,
  Interview,
  ThunkDispatch,
  ZipUpload,
} from "../reducers/types";

const MAX_ADDITIONAL_VISIBLE_UPLOADED_FILE_DIFFS = 3;

interface Props extends StyledComponentProps {
  interview: Interview;
  zipUpload: ZipUpload;
  clearUploadedZipFile: () => any;
  uploadZipFile: (interview: Interview, form: HTMLFormElement) => any;
}

const styles = (theme: Theme) =>
  createStyles({
    zipForm: {
      alignItems: "center",
      display: "flex",
      height: 44,
      marginBottom: 0,
    },
    zipUploadInProgress: {
      alignItems: "center",
      display: "flex",
    },
    zipUploadProgressBarContainer: {
      background: "#ddd",
      borderRadius: 3,
      flex: 1,
      height: 6,
      marginLeft: 15,
      overflow: "hidden",
      position: "relative",
    },
    zipUploadProgressBar: {
      background: theme.palette.primary.main,
      bottom: 0,
      borderRadius: 3,
      left: 0,
      position: "absolute",
      top: 0,
      transition: "width 0.5s",
    },
    zipUploadRemove: {
      marginLeft: 5,
    },
    uploadedFilesTable: {
      "& tr": {
        height: 28,
      },
      "& tr:first-child": {
        borderTop: "solid 1px rgb(224, 224, 224)",
      },
    },
    additionalFilesRow: {
      "& td": {
        borderBottom: "none",
        fontWeight: 500,
      },
    },
    chip: {
      fontSize: 11,
      height: 17,
      marginLeft: 10,
    },
    chipAdded: {
      background: "#ade26f",
    },
    chipRemoved: {
      background: "#ffcdd2",
    },
    linesAddedPositive: {
      color: "#188038",
    },
    linesAddedNegative: {
      color: "#d93025",
    },
  });

class ZipUploader extends React.Component<Props> {
  zipUploadForm?: HTMLFormElement;

  getVisibleUploadedFiles(): FileDiff[] {
    // We want to show all of the base files and up to 3 additional files.
    const {uploadedFiles} = this.props.zipUpload;
    let numAdditionalFiles = 0;
    return uploadedFiles.filter((fileDiff) => {
      if (!fileDiff.added) return true;
      if (numAdditionalFiles === MAX_ADDITIONAL_VISIBLE_UPLOADED_FILE_DIFFS) {
        return false;
      }
      numAdditionalFiles++;
      return true;
    });
  }

  @autobind
  onClearUploadedCode() {
    this.props.clearUploadedZipFile();
  }

  @autobind
  async onZipInputChange() {
    if (this.zipUploadForm) {
      await this.props.uploadZipFile(this.props.interview, this.zipUploadForm);
    }
  }

  renderUploadedFiles(): React.ReactNode {
    const {classes, zipUpload} = this.props;
    const visibleUploadedFiles = this.getVisibleUploadedFiles();
    const numAdditionalFiles =
      zipUpload.uploadedFiles.length - visibleUploadedFiles.length;
    if (!visibleUploadedFiles.length) return null;
    return (
      <Table className={classes?.uploadedFiles} size="small">
        <TableBody className={classes?.uploadedFilesTable}>
          {visibleUploadedFiles.map((fileDiff) => (
            <TableRow key={fileDiff.path}>
              <TableCell>
                {fileDiff.path}
                {fileDiff.added ? (
                  <Chip
                    label="Added"
                    className={classNames(classes?.chip, classes?.chipAdded)}
                  />
                ) : null}
                {fileDiff.removed ? (
                  <Chip
                    label="Removed"
                    className={classNames(classes?.chip, classes?.chipRemoved)}
                  />
                ) : null}
              </TableCell>
              <TableCell align="right">
                {this.renderNumLinesAdded(fileDiff)}
              </TableCell>
            </TableRow>
          ))}
          {numAdditionalFiles ? (
            <TableRow className={classes?.additionalFilesRow}>
              <TableCell colSpan={2}>
                +{numAdditionalFiles} more file
                {numAdditionalFiles > 1 ? "s" : ""}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    );
  }

  renderNumLinesAdded(fileDiff: FileDiff): React.ReactNode {
    const {classes} = this.props;
    if (!fileDiff.numLinesAdded) return null;
    return (
      <span
        className={classNames(
          fileDiff.numLinesAdded > 0 && classes?.linesAddedPositive,
          fileDiff.numLinesAdded < 0 && classes?.linesAddedNegative,
        )}
      >
        {fileDiff.numLinesAdded > 0 ? "+" : ""}
        {fileDiff.numLinesAdded}
      </span>
    );
  }

  render() {
    const {classes, zipUpload} = this.props;
    if (zipUpload.file) {
      return (
        <>
          <div className={classes?.zipUploadInProgress}>
            <Typography>{zipUpload.file.name}</Typography>
            {!zipUpload.isUploaded ? (
              <div className={classes?.zipUploadProgressBarContainer}>
                <div
                  className={classes?.zipUploadProgressBar}
                  style={{width: `${zipUpload.progress * 100}%`}}
                />
              </div>
            ) : null}
            <IconButton
              className={classes?.zipUploadRemove}
              onClick={this.onClearUploadedCode}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          {this.renderUploadedFiles()}
        </>
      );
    }
    return (
      <form
        ref={(ref) => (this.zipUploadForm = ref ?? undefined)}
        encType="multipart/form-data"
        className={classes?.zipForm}
      >
        <input
          accept=".zip"
          name={ZIP}
          onChange={this.onZipInputChange}
          type="file"
        />
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  interview: state.interview,
  zipUpload: state.zipUpload,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  uploadZipFile: (interview: Interview, form: HTMLFormElement) =>
    dispatch(uploadZipFile(interview, form)),
  clearUploadedZipFile: () => dispatch(clearUploadedZipFile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ZipUploader));
