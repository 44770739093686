import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {getUserIdToken} from "../helpers/auth";
import {
  getEndTimeFieldNameForModule,
  getOpenTimeFieldNameForModule,
  getOpenTimeMs,
} from "../helpers/interview";
import {
  m2BaseZipDownloadUrl,
  m2CandidateZipDownloadUrl,
  unresolveCommentsUrl,
  updateInterviewUrl,
} from "../helpers/urls/interviewUrls";
import {AppState, ModuleId} from "../reducers/types";
import {post} from "../util/fetch";
import {ErrorAction, InterviewModuleScreenAction} from "./actionTypes";
import {setErrorMessage} from "./errorActions";
import {LogAction, logFEInfo} from "./serverInfoActions";
import {toast} from "./toastActions";

type Action = InterviewModuleScreenAction | ErrorAction;
type AsyncAction = ThunkAction<Promise<void>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

const DOWNLOAD_DELAY = 2000;

export function isStarting(value: boolean): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.IS_STARTING",
    value,
  };
}

export function isSubmitting(value: boolean): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.IS_SUBMITTING",
    value,
  };
}

export function toggleScreenInstructions(): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.TOGGLE_INSTRUCTIONS",
  };
}

export function toggleConfirmDialog(): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.TOGGLE_CONFIRM_DIALOG",
  };
}

export function toggleSubmittedDialog(): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.TOGGLE_SUBMITTED_DIALOG",
  };
}

function isUnresolvingComments(value: boolean): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.IS_UNRESOLVING_COMMENTS",
    value,
  };
}

function setDocUpdateTime(value: number): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.SET_DOC_UPDATE_TIME",
    value,
  };
}

export function isDownloadingZip(value: boolean): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.IS_DOWNLOADING_ZIP",
    value,
  };
}

function isBBDocDisabledOrDisabling(
  value: boolean,
): InterviewModuleScreenAction {
  return {
    type: "INTERVIEW_MODULE_SCREEN.IS_BBDOC_DISABLED_OR_DISABLING",
    value,
  };
}

export function readyToStart(moduleId: ModuleId): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview} = getState();
    if (getOpenTimeMs(interview, moduleId)) {
      console.info("Module is already open");
      return;
    }
    dispatch(isStarting(true));
  };
}

export function start(moduleId: ModuleId): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      if (getOpenTimeMs(interview, moduleId)) {
        console.info("Module is already open");
        dispatch(isStarting(false));
        return;
      }
      const openTimeFieldName = getOpenTimeFieldNameForModule(moduleId);
      // Return if open time is already set.
      if (openTimeFieldName && interview[openTimeFieldName]) return;
      const updates = {
        // The open-time value will be generated on the server, so we can just
        // pass 0 as a placeholder.
        ...(openTimeFieldName && {[openTimeFieldName]: 0}),
      };
      const response = await post(
        updateInterviewUrl(interview.id),
        {updates},
        {token: await getUserIdToken()},
      );
      const {error, hasInvalidFields} = await response.json();
      // It's possible for the open time to not be set on the FE yet even if it
      // is actually set in Firestore. In this case, the node server will return
      // a 400 error if the FE tries to set the open time again. If that happens
      // we can just ignore the hasInvalidFields error.
      if (error && !hasInvalidFields) {
        console.error("Error starting module:", error);
        dispatch(setErrorMessage("Unable to start interview."));
      } else {
        // Wait before changing the state so the button doesn't flash.
        await new Promise((resolve) => setTimeout(resolve, 1000));
        dispatch(isStarting(false));
      }
    } catch (err) {
      console.error("Error starting module", err);
      dispatch(setErrorMessage("Unable to start interview."));
    }
  };
}

export function end(moduleId: ModuleId): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      const endTimeFieldName = getEndTimeFieldNameForModule(moduleId);
      // Return if end time is already set.
      if (endTimeFieldName && interview[endTimeFieldName]) return;
      const updates = {
        // The end-time value will be generated on the server, so we can just
        // pass 0 as a placeholder.
        ...(endTimeFieldName && {[endTimeFieldName]: 0}),
      };
      const response = await post(
        updateInterviewUrl(interview.id),
        {updates},
        {token: await getUserIdToken()},
      );
      const {error, hasInvalidFields} = await response.json();
      // It's possible for the end time to not be set on the FE yet even if it
      // is actually set in Firestore. In this case, the node server will return
      // a 400 error if the FE tries to set the end time again. If that happens
      // we can just ignore the hasInvalidFields error.
      if (error && !hasInvalidFields) {
        console.error("Error ending module:", error);
        dispatch(setErrorMessage("Unable to end module."));
      }
    } catch (err) {
      console.error("Error ending module", err);
      dispatch(setErrorMessage("Unable to end the module."));
    }
  };
}

export function unresolveComments(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      const data = {atsId: interview.id};
      dispatch(isUnresolvingComments(true));
      const response = await post(unresolveCommentsUrl(), data);
      const {error} = await response.json();
      if (error) {
        throw new Error(error);
      } else {
        dispatch(toast("Re-opened all comments."));
        dispatch(setDocUpdateTime(Date.now()));
      }
    } catch (err) {
      dispatch(toast("Unable to unresolve comments.", 5000));
    }
    dispatch(isUnresolvingComments(false));
  };
}

export function downloadZip(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview, m2InterviewScreenState} = getState();
    const {zipDownloadType} = m2InterviewScreenState;
    try {
      dispatch(isDownloadingZip(true));
      const url =
        zipDownloadType === "base"
          ? m2BaseZipDownloadUrl(interview.id)
          : m2CandidateZipDownloadUrl(interview.id);
      window.location.assign(url);
      // Emulate async download
      await new Promise((resolve) => setTimeout(resolve, DOWNLOAD_DELAY));
      dispatch(isDownloadingZip(false));
    } catch (err) {
      console.error("Error downloading interview materials:", err);
      dispatch(setErrorMessage("Unable to download interview materials."));
    }
  };
}

export function disableBBDoc(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      if (interview.m1OpenTime) {
        console.info("Interview is already started");
        return;
      }
      dispatch(isBBDocDisabledOrDisabling(true));
      dispatch(logFEInfo(LogAction.DISABLED_BBDOC_FOR_DICTATION, {}));
      const updates = {
        enableBBDoc: false,
      };
      const response = await post(
        updateInterviewUrl(interview.id),
        {updates},
        {token: await getUserIdToken()},
      );
      const {error} = await response.json();
      if (error) {
        throw new Error(error);
      } else {
        dispatch(toast("Configured for dictation."));
      }
    } catch (err) {
      dispatch(isBBDocDisabledOrDisabling(false));
      dispatch(
        setErrorMessage(
          "Unable to configure for dictation, please submit a help request.",
        ),
      );
    }
  };
}
