import {ThunkAction} from "redux-thunk";
import {getUserIdToken} from "../helpers/auth";
import {bbhcOptinUrl, bbhcOptoutUrl} from "../helpers/urls/bbhcUrls";
import {Interview, ThunkDispatch} from "../reducers/types";
import {post} from "../util/fetch";
import {BBHCAction} from "./actionTypes";
import {setErrorMessage, UnexpectedServerResponse} from "./errorActions";
import {fetchInterview, getSupportEmail} from "./interviewActions";

export function optinToBBHC(
  interview: Interview,
): ThunkAction<Promise<void>, {}, {}, BBHCAction> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      const response = await post(
        bbhcOptinUrl(interview.id),
        {},
        {token: await getUserIdToken()},
      );
      const json = await response.json();
      if (json) {
        dispatch(fetchInterview(interview.id));
      } else {
        throw new UnexpectedServerResponse(json);
      }
    } catch (err) {
      dispatch(
        setErrorMessage("Unable to opt-in to Byteboard Hiring Consortium."),
      );
      throw err;
    }
  };
}

export function optoutOfBBHC(
  interview: Interview,
): ThunkAction<Promise<void>, {}, {}, BBHCAction> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      const response = await post(
        bbhcOptoutUrl(interview.id),
        {},
        {token: await getUserIdToken()},
      );
      const json = await response.json();
      if (json) {
        dispatch(fetchInterview(interview.id));
      } else {
        throw new UnexpectedServerResponse(json);
      }
    } catch (err) {
      dispatch(
        setErrorMessage(
          `Unable to opt-out of Byteboard Hiring Consortium. Please contact ${getSupportEmail()}.`,
        ),
      );
      throw err;
    }
  };
}
