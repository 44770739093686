import {
  StyledComponentProps,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import * as React from "react";
import {connect} from "react-redux";
import {fetchInterview} from "../../actions/interviewActions";
import * as m2InterviewScreenActions from "../../actions/m2InterviewScreenActions";
import {toggleZipUploadDialog} from "../../actions/zipUploadActions";
import interviewTimeElapsed from "../../audio/interviewTimeElapsed.mp3";
import tenMinutesRemaining from "../../audio/tenMinutesRemaining.mp3";
import {instructionsTextStyle} from "../../components/Instructions";
import {RedirectRule, withInterview} from "../../components/InterviewLoader";
import {SUBMITTED_DIALOG_REDIRECT_TIME_MS} from "../../components/M2SubmittedDialog";
import {
  isModuleInProgress,
  isZipM2,
  willPlayTenMinutesRemainingAudio,
} from "../../helpers/interview";
import {overviewUrl, surveyUrl} from "../../helpers/urls/routerUrls";
import {
  AppState,
  Flags,
  Interview,
  M2InterviewScreenState,
  ThunkDispatch,
} from "../../reducers/types";
import {getServerTime as getServerTimeFn} from "../../util/time";
import CloudShellInterviewInstructions from "./CloudShell/InterviewInstructions";
import ZipInterviewInstructions from "./Zip/InterviewInstructions";

const {
  toggleConfirmDialog,
  setInstructionsIndex,
  isScreenOpen,
  fetchRunShFileName,
  fetchProjectDirectory,
  fetchProjectName,
  fetchHasModifiedM2Code,
  setSwitchedToCloudshellFromZip,
} = m2InterviewScreenActions;

// M2SubmittedDialog autosubmits after SUBMITTED_DIALOG_REDIRECT_TIME_MS. Add
// one minute so that the redirect rule won't accidentally kick in before the
// autosubmit is complete.
const END_TIME_REDIRECT_BUFFER_MS =
  SUBMITTED_DIALOG_REDIRECT_TIME_MS + 1000 * 60;
const TEN_MINUTES_MS = 10 * 60 * 1000;
const zipUploadTimeMin = 5;

interface PassedProps {
  atsId: string;
}
interface StateProps {
  interview: Interview;
  flags: Flags;
  m2InterviewScreenState: M2InterviewScreenState;
  getServerTime: () => number;
}
interface DispatchProps {
  setInstructionsIndex: (value: number) => any;
  fetchInterview: (atsId: string) => any;
  toggleConfirmDialog: () => any;
  toggleZipUploadDialog: (value?: boolean) => any;
  isScreenOpen: (value: boolean) => any;
  fetchRunShFileName: () => any;
  fetchProjectDirectory: typeof fetchProjectDirectory;
  fetchProjectName: typeof fetchProjectName;
  fetchHasModifiedM2Code: typeof fetchHasModifiedM2Code;
  setSwitchedToCloudshellFromZip: () => void;
}

type Props = StyledComponentProps & StateProps & DispatchProps & PassedProps;

const styles = () =>
  createStyles({
    instructionsText: instructionsTextStyle,
  });

class M2InterviewScreen extends React.Component<Props> {
  private autoShowZipDialogTimeoutId: any;
  private tenMinutesRemainingTimeoutId: any;
  private tenMinutesRemainingAudio: HTMLAudioElement = new Audio(
    tenMinutesRemaining,
  );
  private timeElapsedAudio: HTMLAudioElement = new Audio(interviewTimeElapsed);

  async componentDidMount() {
    const {atsId, interview} = this.props;
    const {m2Type, m2OpenTime} = interview;
    this.props.isScreenOpen(true);
    // Make sure interview is up-to-date.
    await this.props.fetchInterview(atsId);
    this.props.fetchRunShFileName();
    this.props.fetchHasModifiedM2Code();
    if (m2Type === "zip") {
      if (m2OpenTime && this.getImplementationTimeRemaining() < 0) {
        this.props.toggleZipUploadDialog(true);
      }
    } else {
      this.props.fetchProjectDirectory();
      this.props.fetchProjectName();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.interview.m2Type === "zip") {
      this.autoShowZipDialogWhenTimeExpires();
    }
    if (willPlayTenMinutesRemainingAudio(this.props.interview)) {
      this.playTenMinutesRemainingAudio();
    }
    // Candidate switched from zip to Cloud Shell.
    if (
      prevProps.interview.m2Type === "zip" &&
      this.props.interview.m2Type === "cloudshell"
    ) {
      this.props.setSwitchedToCloudshellFromZip();
      this.props.setInstructionsIndex(0);
      this.props.fetchProjectDirectory();
      this.props.fetchProjectName();
    }
    // Candidate switched from Cloud Shell to zip.
    if (
      prevProps.interview.m2Type === "cloudshell" &&
      this.props.interview.m2Type === "zip"
    ) {
      this.props.fetchHasModifiedM2Code();
    }
    // Candidate just started m2.
    if (
      !this.hasStarted(prevProps.interview) &&
      this.hasStarted(this.props.interview)
    ) {
      this.onNextInstructions();
    }
  }

  componentWillUnmount() {
    this.props.isScreenOpen(false);
    clearTimeout(this.autoShowZipDialogTimeoutId);
    clearTimeout(this.tenMinutesRemainingTimeoutId);
  }

  hasStarted(interview: Interview): boolean {
    const {getServerTime} = this.props;
    return isModuleInProgress("m2", interview, getServerTime());
  }

  getImplementationTimeRemaining(): number {
    const {interview, getServerTime} = this.props;
    const {m2OpenTime = 0, m2Duration = 0} = interview;
    return m2OpenTime + m2Duration - getServerTime();
  }

  autoShowZipDialogWhenTimeExpires(): void {
    const {interview} = this.props;
    if (!interview.m2OpenTime) return;
    clearTimeout(this.autoShowZipDialogTimeoutId);
    if (this.getImplementationTimeRemaining() > 0) {
      this.autoShowZipDialogTimeoutId = setTimeout(() => {
        this.props.toggleZipUploadDialog(true);
        this.playAudio(this.timeElapsedAudio);
      }, this.getImplementationTimeRemaining());
    }
  }

  playTenMinutesRemainingAudio() {
    const {interview} = this.props;
    if (!interview.m2OpenTime) return;
    clearTimeout(this.tenMinutesRemainingTimeoutId);
    if (this.getImplementationTimeRemaining() > TEN_MINUTES_MS) {
      this.tenMinutesRemainingTimeoutId = setTimeout(() => {
        this.playAudio(this.tenMinutesRemainingAudio);
      }, this.getImplementationTimeRemaining() - TEN_MINUTES_MS);
    }
  }

  async playAudio(audio: HTMLAudioElement): Promise<void> {
    try {
      await audio.play();
    } catch (err) {
      // Playing the audio will throw an error in Chrome if the user hasn't
      // interacted with the page. We can just swallow the error if it doesn't
      // play.
    }
  }

  @autobind
  onNextInstructions() {
    const {instructionsIndex} = this.props.m2InterviewScreenState;
    if (instructionsIndex < 3) {
      this.props.setInstructionsIndex(instructionsIndex + 1);
    } else {
      this.props.toggleConfirmDialog();
    }
  }

  render(): React.ReactNode {
    const {atsId, flags, interview, m2InterviewScreenState} = this.props;
    if (isZipM2(interview)) {
      return (
        <ZipInterviewInstructions
          atsId={atsId}
          flags={flags}
          interview={interview}
          m2InterviewScreenState={m2InterviewScreenState}
        />
      );
    }
    return (
      <CloudShellInterviewInstructions
        flags={flags}
        interview={interview}
        m2InterviewScreenState={m2InterviewScreenState}
      />
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  interview: state.interview,
  flags: state.flags,
  m2InterviewScreenState: state.m2InterviewScreenState,
  getServerTime: () => getServerTimeFn(state.serverInfo.serverTimeOffset),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  toggleZipUploadDialog: (value?: boolean) => {
    return dispatch(toggleZipUploadDialog(value));
  },
  fetchInterview: (atsId: string) => {
    return dispatch(fetchInterview(atsId));
  },
  toggleConfirmDialog: () => dispatch(toggleConfirmDialog()),
  setInstructionsIndex: (value: number) => {
    return dispatch(setInstructionsIndex(value));
  },
  isScreenOpen: (value: true) => dispatch(isScreenOpen(value)),
  fetchRunShFileName: () => dispatch(fetchRunShFileName()),
  fetchProjectDirectory: () => dispatch(fetchProjectDirectory()),
  fetchProjectName: () => dispatch(fetchProjectName()),
  fetchHasModifiedM2Code: () => dispatch(fetchHasModifiedM2Code()),
  setSwitchedToCloudshellFromZip: () => {
    return dispatch(setSwitchedToCloudshellFromZip());
  },
});

const redirectRules: RedirectRule[] = [
  {
    match: (interview: Interview) => !interview.m1EndTime,
    redirect: (interview) => overviewUrl(interview.id),
  },
  {
    match: (interview: Interview, serverTime: number) => {
      const {m2Type} = interview;
      if (m2Type === "zip") {
        return (
          !!interview.m2EndTime &&
          interview.m2EndTime +
            zipUploadTimeMin * 1000 * 60 +
            END_TIME_REDIRECT_BUFFER_MS <
            serverTime
        );
      }
      return (
        !!interview.m2EndTime &&
        interview.m2EndTime + END_TIME_REDIRECT_BUFFER_MS < serverTime
      );
    },
    redirect: (interview) => surveyUrl(interview.id),
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withInterview(redirectRules)(M2InterviewScreen)));
