import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {getUserIdToken} from "../helpers/auth";
import {helpEmailUrl} from "../helpers/urls/interviewUrls";
import {AppState} from "../reducers/types";
import {getBrowserName, getOS} from "../util/browser";
import {post} from "../util/fetch";
import {HelpDialogAction} from "./actionTypes";
import {toast} from "./toastActions";

type Action = HelpDialogAction;
type AsyncAction = ThunkAction<Promise<void>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

export const SHOW_HELP_DIALOG = "SHOW_HELP_DIALOG";
export const HIDE_HELP_DIALOG = "HIDE_HELP_DIALOG";
export const IS_SUBMITTING_HELP_FORM = "IS_SUBMITTING_HELP_FORM";
export const DID_SUBMIT_HELP_FORM = "DID_SUBMIT_HELP_FORM";
export const RESET_HELP_FORM = "RESET_HELP_FORM";
export const SET_ACTIVE_TOPIC_ID = "SET_ACTIVE_TOPIC_ID";
export const SET_CONTACT_FORM_TEXT = "SET_CONTACT_FORM_TEXT";

export function showHelpDialog(activeTopicId?: string): HelpDialogAction {
  return {
    type: SHOW_HELP_DIALOG,
    activeTopicId,
  };
}

export function setActiveTopicId(value: string): HelpDialogAction {
  return {
    type: SET_ACTIVE_TOPIC_ID,
    value,
  };
}

export function setContactFormText(value: string): HelpDialogAction {
  return {
    type: SET_CONTACT_FORM_TEXT,
    value,
  };
}

export function submitHelpForm(message: string): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({type: IS_SUBMITTING_HELP_FORM});
    const {interview} = getState();
    const data = {
      atsId: interview.id,
      message,
      browser: getBrowserName(),
      os: getOS(),
      error: "",
      log: {action: "SUPPORT_REQUESTED"},
    };
    const response = await post(helpEmailUrl(), data, {
      token: await getUserIdToken(),
    });
    const {error} = await response.json();
    if (error) {
      dispatch(toast("Failed to send message. Try again later."));
    } else {
      dispatch({type: DID_SUBMIT_HELP_FORM});
    }
  };
}

export function hideHelpDialog() {
  return async (
    dispatch: ThunkDispatch<AppState, Record<string, never>, HelpDialogAction>,
  ): Promise<void> => {
    dispatch({type: HIDE_HELP_DIALOG});
    // Wait for the dialog to close before resetting it.
    await new Promise((resolve) => setTimeout(resolve, 500));
    dispatch({type: RESET_HELP_FORM});
  };
}
