import {
  createStyles,
  StyledComponentProps,
  withStyles,
} from "@material-ui/core/styles";
import type {Theme} from "@material-ui/core/styles/createTheme";
import Typography from "@material-ui/core/Typography";
import autobind from "autobind-decorator";
import * as React from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {HelpDialogAction} from "../actions/actionTypes";
import {showHelpDialog} from "../actions/helpDialogActions";
import {getSupportEmail} from "../actions/interviewActions";
import {brand} from "../branding";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Link from "../components/Link";
import UnorderedList from "../components/UnorderedList";
import {AppError, AppState} from "../reducers/types";

const genericTips: React.ReactNode[] = [
  "Some browser extensions may prevent the popup we use to authenticate" +
    " (i.e. Ad Blockers). Please disable your extensions, or try opening" +
    " the interview in an private browsing window.",
  "Try reloading the page.",
  "If all of the above fails, try switching browsers. We support" +
    " Google Chrome, Mozilla Firefox, Microsoft Edge, Safari, and" +
    " Microsoft Internet Explorer 11+.",
];

interface Props extends StyledComponentProps {
  error: AppError;
  supportEmail: string;
  showHelpDialog: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logo: {
      flexShrink: 0,
      height: brand.logoMarkDimensions.height,
      width: brand.logoMarkDimensions.width,
    },
    error: {
      fontSize: 18,
      fontWeight: 300,
    },
    errorBold: {
      fontWeight: 400,
    },
    content: {
      marginLeft: 30,
    },
  });

class ErrorScreen extends React.Component<Props> {
  @autobind
  onSendMessage() {
    this.props.showHelpDialog();
  }

  renderMessage(): React.ReactNode {
    const {classes, error} = this.props;
    const messagePrefix = error.isNotification ? "" : "An error occurred: ";
    return (
      <Typography className={classes?.error}>
        {messagePrefix}
        <span className={classes?.errorBold}>{error.errorMessage}</span>
      </Typography>
    );
  }

  renderTips(): React.ReactNode {
    const {classes, error, supportEmail} = this.props;
    const tips: React.ReactNode[] = [...genericTips, ...error.tips].map(
      (tip, index) => <Typography key={index}>{tip}</Typography>,
    );
    if (error.hideTips) return null;
    return (
      <React.Fragment>
        <br />
        <br />
        <Typography paragraph={true}>
          The following may help fix the issue:
        </Typography>
        <UnorderedList className={classes?.list} items={tips} />
        <br />
        <Typography paragraph={true}>
          If the problem persists,{" "}
          <Link to={document.location} onClick={this.onSendMessage}>
            send us a message
          </Link>{" "}
          or send an email to {supportEmail} and a team member will assist you.
        </Typography>
      </React.Fragment>
    );
  }

  render(): React.ReactNode {
    const {classes} = this.props;
    return (
      <CenteredScreenContent>
        <div className={classes?.root} aria-live="assertive">
          <img
            src={brand.logoMark}
            className={classes?.logo}
            aria-hidden={true}
          />
          <div className={classes?.content}>
            <Typography variant="h1">Oh no!</Typography>
            {this.renderMessage()}
            {this.renderTips()}
          </div>
        </div>
      </CenteredScreenContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  error: state.error,
  supportEmail: getSupportEmail(),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, HelpDialogAction>,
) => ({
  showHelpDialog: () => dispatch(showHelpDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ErrorScreen));
